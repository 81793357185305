const LOWER_CHARS = 'abcdefghijklmnopqrstuvwxyz0123456789'
const LOWER_CHARS_LEN = LOWER_CHARS.length

const TRANSLATE_INP = 'àáâãäåæÆçèéêëƒìíîïñðòóôõöšýùúüûž'
const TRANSLATE_OUT = 'aaaaaaaaceeeefiiiinoooooosyyuuuz'
const ASCII_MAP = {}
for (let i = 0; i < TRANSLATE_INP.length; i++) {
  ASCII_MAP[TRANSLATE_INP[i]] = TRANSLATE_OUT[i]
}
const ASCII_REGEX = new RegExp(Object.keys(ASCII_MAP).join('|'), 'g')

const EXTERNAL_VIDEO_DOMAINS = {
  zoom: 'zoom.us',
  meet: 'meet.google.com',
  teams: 'teams.microsoft.com',
}

const BYTES_REFERENCES = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

const ERRORS = {
  Required: () => 'Obrigatório',
  MaxStringLength: () => 'Defina um texto mais curto',
  InvalidNumber: () => 'Número inválido',
  AnonymousNotAllowed: () => 'Esta sala não permite acesso a convidados (sem conta).',
  InvalidCode: () => 'Código inválido',
  CodeAlreadyExists: () => 'Código já definido',
  UserAlreadyExists: () => 'Utilizador já existe',
  MaxAttempts: () => 'Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.',
  InvalidDate: () => `Data inválida`,
  InvalidTime: () => `Horário inválido`,
  NotFound: (error, context = null) => {
    if (context == 'meeting') {
      return 'A sala não existe.'
    } else {
      return 'Não existe.'
    }
  },
  MinOptions: error => {
    return `Defina mais opções (mínimo ${error.min})`
  },
  MaxOptions: error => {
    return `Defina menos opções (máximo ${error.max})`
  },
  MinNumber: error => {
    return `O número tem de ser maior ou igual a ${error.min_number}`
  },
  MaxNumber: error => {
    return `O número tem de ser menor ou igual a ${error.max_number}`
  },
  MinDate: error => {
    return `A data tem de ser maior ou igual a ${error.min_date}`
  },
  MaxDate: error => {
    return `A data tem de ser menor ou igual a ${error.min_date}`
  },
  MinTime: error => {
    return `O horário tem de ser maior que ${error.min_time}`
  },
}

const FILE_ICONS = {
  mpg4: 'movie',
  mp4: 'movie',
  avi: 'movie',
  mov: 'movie',
  pdf: 'file-pdf',
  ppt: 'file-ppt',
  pptx: 'file-ppt',
  key: 'keynote',
  docx: 'file-word',
  doc: 'file-word',
  xls: 'file-excel',
  xlsx: 'file-excel',
  png: 'image',
  jpeg: 'image',
  jpg: 'image',
}

const COUNTRY_LIST_ALPHA2 = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands (the)',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  CO: 'Colombia',
  KM: 'Comoros (the)',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo (the)',
  CK: 'Cook Islands (the)',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (the) [Malvinas]',
  FO: 'Faroe Islands (the)',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories (the)',
  GA: 'Gabon',
  GM: 'Gambia (the)',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (the)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic (the)",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines (the)',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan (the)',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands (the)',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates (the)',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  UM: 'United States Minor Outlying Islands (the)',
  US: 'United States of America (the)',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
}

export default {
  countryListAlpha2() {
    return COUNTRY_LIST_ALPHA2
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },
  randomLowerString(length) {
    var randomString = ''
    for (var i = 0; i < length; i++) {
      randomString += LOWER_CHARS[Math.floor(Math.random() * LOWER_CHARS_LEN)]
    }
    return randomString
  },
  JSONSafeStringify(obj) {
    let cache = []
    return JSON.stringify(obj, (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value
    )
  },

  isProduction() {
    return process.env.NODE_ENV == 'production'
  },

  isMobile() {
    return window.innerWidth < 640
  },

  getUrlsFromString(content) {
    return content.match(/(https?:\/\/[^ ]*)/)
  },
  isValidEmail(email) {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  },
  isValidURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(str)
  },
  getExternalVideoAppFromUrl(url) {
    if (url && this.isValidURL(url)) {
      const hostname = new URL(url).hostname.toLocaleLowerCase()
      for (let [app, domain] of Object.entries(EXTERNAL_VIDEO_DOMAINS)) {
        if (hostname === domain || hostname.endsWith(`.${domain}`)) {
          return app
        }
      }
    }
  },

  equal_set(first, second) {
    if (first.size !== second.size) return false
    for (var i of first) if (!second.has(i)) return false
    return true
  },
  equal_array(first, second) {
    if (first.length !== second.length) return false
    for (var i of first) if (!second.includes(i)) return false
    return true
  },

  escapeForRegex(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  },
  replaceASCII(value) {
    return value.toLowerCase().replaceAll(ASCII_REGEX, function(matched) {
      return ASCII_MAP[matched] || matched
    })
  },
  existsInSearchFactory(search) {
    const regexItems = []
    const items = this.replaceASCII(search).split(' ')
    for (let item of items) {
      if (item) {
        regexItems.push(this.escapeForRegex(item))
      }
    }

    if (!regexItems.length) {
      return value => {
        return !!value
      }
    } else {
      const regex = new RegExp('.*' + regexItems.join('.*') + '.*')
      return value => {
        return value ? !!this.replaceASCII(value).match(regex) : false
      }
    }
  },

  stripHTML(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  },

  async buildMediaDevices() {
    const cameras = []
    const audioInputs = []
    const audioOutputs = []

    const counter = {}
    let devices = await navigator.mediaDevices.enumerateDevices()
    for (let device of devices) {
      if (!device || !device.deviceId) continue

      if (device.kind === 'videoinput') {
        let label = device.label
        if (!label) {
          if (!counter.videoinput) counter.videoinput = 1
          counter.videoinput += 1
          label = `Camera ${counter.videoinput}`
        }

        cameras.push({
          id: device.deviceId,
          label: label,
        })
      } else if (device.kind === 'audioinput') {
        let label = device.label
        if (!label) {
          if (!counter.audioinput) counter.audioinput = 1
          counter.audioinput += 1
          label = `Microphone ${counter.audioinput}`
        }

        audioInputs.push({
          id: device.deviceId,
          label: label,
        })
      } else if (device.kind === 'audiooutput') {
        let label = device.label
        if (!label) {
          if (!counter.audiooutput) counter.audiooutput = 1
          counter.audiooutput += 1
          label = `Speaker ${counter.audiooutput}`
        }

        audioOutputs.push({
          id: device.deviceId,
          label: label,
        })
      }
    }

    return {
      cameras,
      audioInputs,
      audioOutputs,
    }
  },

  recordingsPosterURL(recording) {
    try {
      return require(`@/fw-modules/fw-core-vue/interface/assets/recordings/${recording.app}.png`)
    } catch {
      return require('@/fw-modules/fw-core-vue/interface/assets/recordings/ucmeetings.png')
    }
  },

  getFileIcon(filename) {
    if (filename) {
      const parts = filename.split('.')
      if (parts.length > 1) {
        const icon = FILE_ICONS[parts[parts.length - 1]]
        if (icon) {
          return icon
        }
      }
    }
    return 'file'
  },

  downloadFile(url, filename) {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  },

  bytesToString(value, roundTo = 2) {
    if (!value) return '0 B'

    let idx = null
    for (idx = 0; idx < BYTES_REFERENCES.length - 1; idx++) {
      const number = value / 1000
      if (number < 1) {
        break
      } else {
        value = number
      }
    }

    const roundToNumber = 10 ** roundTo
    value = Math.round((value + Number.EPSILON) * roundToNumber) / roundToNumber
    return `${value} ${BYTES_REFERENCES[idx]}`
  },

  errors(obj, clearReference = false) {
    let items = []
    if (obj) {
      let errorObj
      if (obj.__errors__) {
        errorObj = obj
      } else if (obj.data && obj.data.__errors__) {
        errorObj = obj.data
      } else if (obj.response && obj.response.data && obj.response.data.__errors__) {
        errorObj = obj.response.data
      }

      if (errorObj) {
        items = errorObj.__errors__
        if (clearReference) {
          delete errorObj.__errors__
        }
      }
    }

    return {
      items: items,
      context: null,

      setContext(context) {
        this.context = context
        return this
      },

      exists(key) {
        for (let error of this.items) {
          if (error.key === key) {
            return true
          }
        }
        return false
      },

      get() {
        if (this.items.length) {
          return this.items[0]
        }
      },
      getKey() {
        const error = this.get()
        if (error) {
          return error.key
        }
      },
      getTranslated() {
        const error = this.get()
        if (error) {
          return this.translate(error)
        }
      },

      getField(field) {
        for (let error of this.items) {
          if (error.field === field) {
            return error
          }
        }
      },
      getFieldKey(field) {
        const error = this.getField(field)
        if (error) {
          return error.key
        }
      },
      getFieldTranslated(field) {
        const error = this.getField(field)
        if (error) {
          return this.translate(error)
        }
      },

      translate(error) {
        const func = ERRORS[error.key]
        if (func) {
          return func(error, this.context)
        } else {
          return error.key
        }
      },
      extract() {
        const errors = {}
        for (let error of this.items) {
          if (error.field) {
            errors[error.field] = this.translate(error)
          }
        }
        return errors
      },
    }
  },

  isObjectEmpty(obj) {
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    return (
      obj && // null and undefined check
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    )
  },
}
