import Home from '@/views/Home'
import Logout from '@/views/Logout'
import ExternalAccountSignup from '@/views/ExternalAccountSignup'
import ExternalAccountConfirm from '@/views/ExternalAccountConfirm'
import ExternalAccountRecoverPassword from '@/views/ExternalAccountRecoverPassword'
import ExternalAccountChangePassword from '@/views/ExternalAccountChangePassword'
import NotFound from '@/fw-modules/fw-core-vue/id/views/NotFound'

export default [
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { requiresAuth: true },
  },
  {
    path: '/external-account',
    name: 'external-account-signup',
    component: ExternalAccountSignup,
    meta: { requiresNoAuth: true },
  },
  {
    path: '/confirm',
    name: 'external-account-confirm',
    component: ExternalAccountConfirm,
    meta: { requiresNoAuth: true },
  },
  {
    path: '/reset',
    name: 'external-account-recover-password',
    component: ExternalAccountRecoverPassword,
    meta: { requiresNoAuth: true },
  },
  {
    path: '/recover',
    name: 'external-account-change-password',
    component: ExternalAccountChangePassword,
    meta: { requiresNoAuth: true },
  },

  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/:app',
    name: 'home-app',
    component: Home,
  },

  {
    path: '*',
    name: 'error',
    component: NotFound,
  },
]
