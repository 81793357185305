<template>
  <b-dropdown
    v-if="userLoggedIn"
    scrollable
    max-height="500"
    aria-role="list"
    position="is-bottom-left"
    class="block-header-apps h-full"
  >
    <fw-button-dropdown slot="trigger" type="transparent" :label="$t('seeApplications')" :chevron="false">
      <fw-icon-apps class="w-5 h-5" />
    </fw-button-dropdown>
    <b-dropdown-item aria-role="menu-item" :focusable="false" custom class="flex justify-between gap-2 items-center">
      <fw-heading size="h3" class="text-gray-500">{{ $t('applications') }}</fw-heading>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="showApps"
      :focusable="false"
      custom
      class="md:w-96 grid grid-cols-3 gap-4 md:gap-5 px-3 pb-5 pt-3"
    >
      <div
        v-for="app in apps"
        :key="app.code"
        aria-role="menuitem"
        class="text-center mx-auto flex flex-col items-center group"
      >
        <button
          :ref="`go-${app.code}`"
          size="xs"
          class="text-center flex flex-col items-center justify-center relative"
          @click="goToApp(app)"
        >
          <div
            class="w-16 h-16 mx-auto group-hover:shadow-2xl shadow-lg flex items-center justify-center overflow-hidden rounded-2xl relative"
          >
            <AppIcon v-if="app.icon" class="w-full h-full" :url="app.icon.url" size="400" />
            <div
              v-else
              class="uppercase h-full w-full bg-gray-300 flex justify-center font-bold text-lg items-center px-3 py-2"
            >
              {{ app.initials }}
            </div>
          </div>
          <v-clamp class="text-sm font-bold mt-2 text-center" autoresize :max-lines="1">{{ app.title }}</v-clamp>
          <v-clamp v-if="app.label" class="text-xs mt-0.5 text-gray-400 text-center" autoresize :max-lines="1">{{
            app.label
          }}</v-clamp>
        </button>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import AppIcon from '@/fw-modules/fw-core-vue/ui/components/AppIcon'

export default {
  components: {
    AppIcon,
  },

  data() {
    return {
      usage: this.$store.state.session.appsUsage || {},
      appName: process.env.VUE_APP_KEY || 'ucfw-app',
    }
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    showApps() {
      return process.env.VUE_APP_KEY != 'ucmeetingscreen'
    },
    apps() {
      return (
        Object.values(this.$store.getters.getApps)
          .filter(app => process.env.VUE_APP_KEY !== app.code)

          // Sort by counter (most used first)
          // Sort by app title (apps with UC prefix first)
          .sort((a, b) => {
            const aCount = this.usage[a.code] || 0
            const bCount = this.usage[b.code] || 0
            if (aCount > bCount) {
              return 1
            } else if (bCount > aCount) {
              return -1
            } else {
              return a.title.localeCompare(b.title)
            }
          })
          .reverse()
      )
    },
  },

  mounted() {
    this.getAppsUsage()
  },

  methods: {
    getBackTo() {
      if (this.backTo == '-1') this.$router.back()
      else this.$router.push(this.backTo)
    },
    async goToApp(app) {
      try {
        const data = await ServiceAuth.getAppUrl(app.code, this.$store.getters.getContext)
        this.openApp(app.code, data.url, app.new_tab)
      } catch (error) {
        console.error(`Failed to get app ${app.url} url`, error)
        this.openApp(app.code, app.url, app.new_tab)
      }
    },
    async openApp(appCode, appUrl, newTab) {
      // Add usage
      if (!(appCode in this.usage)) this.usage[appCode] = 1
      else this.usage[appCode] += 1
      await ServiceSettings.setSetting('apps-usage', JSON.stringify(this.usage))
      this.$store.state.session.appsUsage = this.usage

      if (!newTab) {
        const context = this.$store.getters.getContext
        if (context && context.code == 'in-meeting') {
          newTab = true
        }
      }

      // Open on the same window
      if (!newTab) {
        window.location.href = appUrl
        return
      }
      var newtab = window.open(appUrl, '_blank')
      if (!newtab || newtab.closed || typeof newtab.closed == 'undefined') {
        this.$buefy.dialog.confirm({
          title: this.$t('blockedPopUps'),
          message: this.$t('blockedPopUpsWarning'),
          confirmText: this.$t('openInTab'),
          cancelText: this.$t('closeMessage'),
          type: 'is-warning',
          onConfirm: async () => {
            window.location.href = appUrl
          },
          onCancel: () => {
            return
          },
        })
      }
    },
    async getAppsUsage() {
      if (typeof this.$store.state.session.appsUsage == 'undefined') {
        const usage = await ServiceSettings.getSetting('apps-usage')
        if (!usage) {
          this.usage = {}
        } else {
          for (let [key, value] of Object.entries(JSON.parse(usage.value))) {
            this.usage[key] = Math.max(parseInt(value) || 0, 0)
          }
        }
        this.$store.state.session.appsUsage = this.usage
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "applications": "Aplicações",
    "seeApplications": "Ver aplicações",
    "blockedPopUps": "Pop-ups bloqueados",
    "blockedPopUpsWarning": "O seu navegador parece ter bloqueado a abertura da aplicação num novo separador.<br/><br/><strong>Como resolver</strong><br/>Feche esta mensagem, procure o aviso emitido pelo navegador (normalmente encontra-se junto à barra de endereço) e clique para autorizar. Só precisará realizar esta configuração uma vez.<br/><br/>Em alternativa, pode abrir a aplicação nesta janela.",
    "openInTab": "Abrir neste separador",
    "closeMessage": "Fechar mensagem"
  },
  "en": {
    "applications": "Applications",
    "seeApplications": "See applications",
    "blockedPopUps": "Pop-ups are blocked",
    "blockedPopUpsWarning": "Your browser seems to have blocked the application from opening in a new tab. <br/><br/><strong>How to solve</strong><br/> Close this message, look for the warning issued by the browser (it is usually found next to the address bar) and click to allow it. You only need to perform this setting once.<br/><br/>Alternatively, you can open the application in this window.",
    "openInTab": "Open in this tab",
    "closeMessage": "Close message"
  }
}
</i18n>
