import Vue from 'vue'
import axios from 'axios'
import messages from '@/i18n'
import store from '@/store'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { NotificationProgrammatic as Notification } from 'buefy'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

const PING_EVERY = 10000
const RECONNECT_STALE_AFTER = PING_EVERY * 3
const MAX_RECONNECT_MS = 7 * 24 * 60 * 60 * 1000
const USER_RECONNECT_MS = process.env.VUE_APP_IS_STAGING
  ? (parseInt(localStorage.getItem('wsReconnectSeconds')) || 0) * 1000 || MAX_RECONNECT_MS
  : MAX_RECONNECT_MS
const RECONNECT_MS = Math.min(Math.max(USER_RECONNECT_MS, 60 * 1000), MAX_RECONNECT_MS)

const WS_DATA = {
  isConnected: false,
  firstConnection: true,
  reconnectCalled: false,
  lastMessageDate: null,
  lastPingDate: null,
  authOnConnect: null,
  count: 0,

  authSentDate: null,
  authBackoff: 5 * 1000,
  sendAuth(token) {
    if (!this.isConnected) {
      this.authOnConnect = token
      return
    }

    const now = new Date()
    if (this.authSentDate && now - this.authSentDate <= this.authBackoff) return
    this.authSentDate = now

    Vue.prototype.$socket.sendObj({ application: 'id', code: 'auth', token: token })
    console.debug('Socket auth sent')
  },
}
const WS_SUBSCRIPTIONS = {}

function socketPing() {
  if (WS_DATA.isConnected) {
    try {
      const noMessagesMs = new Date() - WS_DATA.lastMessageDate
      if (noMessagesMs < PING_EVERY) {
        // Ignore ping, we got a message
        return
      } else if (noMessagesMs > RECONNECT_STALE_AFTER) {
        console.warn('No WS messages, reconnect started')
        Vue.prototype.$socket.close()
        return
      }

      if (!WS_DATA.lastPingDate || new Date() - WS_DATA.lastMessageDate >= PING_EVERY) {
        Vue.prototype.$socket.sendObj({ application: 'ping' })
        WS_DATA.lastPingDate = new Date()
      }
    } catch (error) {
      console.error('WS ping error', error)
    }
  }
}

function socketReconnect() {
  console.warn('WS expired, reconnect started')
  Vue.prototype.$socket.close()
}

function callReconnectCallbacks(connectionId) {
  if (!WS_DATA.reconnectCalled) {
    if (WS_DATA.firstConnection) {
      WS_DATA.firstConnection = false
    } else {
      const wsReconnectItems = WS_SUBSCRIPTIONS['ws-reconnect']
      console.warn(`WS reconnect called: ${JSON.stringify(wsReconnectItems)}`)

      if (wsReconnectItems) {
        for (let item of Object.values(wsReconnectItems)) {
          if (item.callbackConfig) {
            item.callbackConfig.connectionId = connectionId
            item.callback(item.callbackConfig)
          } else {
            item.callback(connectionId)
          }
        }
      }

      WS_DATA.reconnectCalled = true
    }
  }
}

function removeUnreadNotificationCore(state, key) {
  for (let idx in state.session.unreadNotifications) {
    const notification = state.session.unreadNotifications[idx]
    if (notification.key === key) {
      state.session.unreadNotifications.splice(idx, 1)
      state.session.unreadNotificationsCount -= 1
      if (notification.application) {
        for (let app of state.session.apps) {
          if (app.code == notification.application) {
            if (app.unread > 0) app.unread -= 1
            break
          }
        }
      }
      break
    }
  }
}

function checkWebsocketCore(state, data) {
  if (!state.socket.connectionId) {
    if (data.callback) {
      if (!WS_SUBSCRIPTIONS.auth) WS_SUBSCRIPTIONS.auth = {}
      WS_SUBSCRIPTIONS.auth[data.name] = data
    }

    if (data.token) WS_DATA.sendAuth(data.token)
  } else if (data.callback) {
    if (data.callbackConfig) {
      data.callbackConfig.connectionId = state.socket.connectionId
      data.callback(data.callbackConfig)
    } else {
      data.callback(state.socket.connectionId)
    }
  }
}

export default {
  setActivityTimer(state, timer) {
    state.activityTimer = timer
  },
  addActivityLog(state, log) {
    if (state.session && state.session.activityLogs) {
      state.session.activityLogs.push(log)
    }
  },
  resetActivityLogs(state) {
    state.session.activityLogs = []
  },
  removeFromExamMessageQueue(state, numberOfMessagesToRemove) {
    state.session.unreadExamWsMessages.splice(0, numberOfMessagesToRemove)
  },
  setLastRoutePath(state, path) {
    state.lastRoutePath = path
  },

  setClockTimer(state, timer) {
    state.clockTimer = timer
  },

  timerTick(state) {
    state.now = state.now + 1000
  },

  setNow(state, now) {
    state.now = now
  },

  setDirtyData(state, data) {
    state.dirtyData = data
  },

  setUser(state, user) {
    state.session.user = user
  },

  setLanguage(state, lang) {
    state.language = lang
    state.locales = messages[lang]
  },

  setSessionError(state, statusKey, errorDescription) {
    state.session.error = true
    state.session.errorType = statusKey
    state.session.errorDescription = errorDescription
  },

  login(state, token) {
    state.session.token = token
  },

  setNewLogin(state, { user, token }) {
    user.photoUrl = ServiceStorage.getUserImageViewUrl(user)

    // End old socket active connection
    if (state.socket.connectionId) Vue.prototype.$socket.close()

    localStorage.setItem('session.token', token)
    localStorage.setItem('session.user', JSON.stringify(user))

    axios.defaults.headers.common['Authorization'] = token
    checkWebsocketCore(state, { token: token })
    state.session.token = token
    state.session.user = user
  },

  setToken(state, token) {
    localStorage.setItem('session.token', token)
    axios.defaults.headers.common['Authorization'] = token
    state.session.token = token
  },

  resetToken(state) {
    state.session.token = ''
    state.session.user = {}
    state.socket.connectionId = null
    WS_DATA.authOnConnect = null
    WS_DATA.authSentDate = null
  },

  logout(state) {
    // Reset all of state session values
    // TODO: Validate this change
    for (let key of Object.keys(state.session)) {
      state.session[key] = undefined
    }
  },
  setApiStatus(state, isActive) {
    state.api.isActive = isActive
  },

  removeUnreadNotification(state, key) {
    removeUnreadNotificationCore(state, key)
  },

  setActiveChat(state, channelKey) {
    state.session.activeChatKey = channelKey

    for (let idx in state.session.unreadChatMessages) {
      const message = state.session.unreadChatMessages[idx]
      if (message.channel_key === channelKey) {
        state.session.unreadChatMessages.splice(idx, 1)
        state.session.unreadChatMessagesCount -= message.unread
        break
      }
    }
  },

  removeActiveChat(state, channelKey) {
    if (channelKey == state.session.activeChatKey) {
      state.session.activeChatKey = null
    }
  },

  setContext(state, context = null) {
    state.session.context = context
  },

  subscribeWS(state, data) {
    // !WARN! Make sure data.name is unique
    if (data.code === 'auth' && state.socket.connectionId) {
      data.callback()
    } else {
      if (!WS_SUBSCRIPTIONS[data.code]) WS_SUBSCRIPTIONS[data.code] = {}
      WS_SUBSCRIPTIONS[data.code][data.name] = data
      console.debug(`Subscription added ${data.code}:${data.name}`)
    }
  },

  unsubscribeWS(state, data) {
    const ref = WS_SUBSCRIPTIONS[data.code]
    if (ref) {
      delete ref[data.name]
      console.debug(`Subscription removed ${data.code}:${data.name}`)
    }
  },

  async checkWebsocket(state, data) {
    checkWebsocketCore(state, data)
    //retry original request?
    if (typeof data.retryCallback === 'function') {
      //Wait before callback?
      if (data.retryCallbackWait && data.retryCallbackWait >= 0) {
        await utils.sleep(data.retryCallbackWait)
      }
      console.debug('executing checkWebsocket callback function')
      if (data.retryCallbackExpectReturn === true) {
        console.debug('with returned data')
        if (data.retryCallbackArgs && Array.isArray(data.retryCallbackArgs)) {
          state.callbackReturnData = data.retryCallback.apply(null, data.retryCallbackArgs)
        } else {
          //callback without arguments
          state.callbackReturnData = data.retryCallback()
        }
      } else {
        console.debug('without returned data')
        if (data.retryCallbackArgs && Array.isArray(data.retryCallbackArgs)) {
          data.retryCallback.apply(null, data.retryCallbackArgs)
        } else {
          //callback without arguments
          data.retryCallback()
        }
      }
    }
  },

  SOCKET_ONOPEN(state, event) {
    console.debug('WS ONOPEN:', event)
    Vue.prototype.$socket = event.currentTarget
    WS_DATA.isConnected = true
    WS_DATA.count = 0

    let token = WS_DATA.authOnConnect
    if (!token && state.session.token) token = state.session.token
    if (token) WS_DATA.sendAuth(token)

    if (state.socket.queue) {
      for (let i = 0; i < state.socket.queue.length; i++) {
        let message = state.socket.queue[i]
        Vue.prototype.$socket.sendObj(message)
        console.debug('Queued WS message sent', message)
      }
    }

    WS_DATA.lastMessageDate = new Date()
    WS_DATA.ping = setInterval(socketPing, parseInt(PING_EVERY / 3))
    WS_DATA.reconnect = setTimeout(socketReconnect, RECONNECT_MS)
  },

  SOCKET_ONCLOSE(state, event) {
    console.debug('WS close', event)
    WS_DATA.isConnected = false
    state.socket.connectionId = null
    WS_DATA.authOnConnect = null
    WS_DATA.authSentDate = null

    if (WS_DATA.ping) {
      clearInterval(WS_DATA.ping)
      delete WS_DATA.ping
    }

    WS_DATA.reconnectCalled = false
    if (WS_DATA.reconnect) {
      clearTimeout(WS_DATA.reconnect)
      delete WS_DATA.reconnect
    }
  },

  SOCKET_ONERROR(state, event) {
    console.error('WS error', event)
  },

  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    if (!message) return

    // For reconnect reasons
    WS_DATA.lastMessageDate = new Date()

    if (!message.id || !message.id.pong) {
      if (!message.chat || !message.chat.newMessage) console.debug(`WS message ${JSON.stringify(message)}`)
      else console.debug(`WS new chat message wst:${message.wst}`)
    }
    console.log('message received ')
    // Check for missed/invalid messages
    if (message.wst) {
      const wsCount = message.wst[0]
      if (wsCount === WS_DATA.count + 1) {
        WS_DATA.count = wsCount
      } else {
        console.warn(`Invalid WS count ${wsCount} localPrevious:${WS_DATA.count}`)
        if (wsCount > WS_DATA.count) WS_DATA.count = wsCount
      }
    }

    if (message.id) {
      if (message.id.auth) {
        let undefinedAuthError = true
        const authMessage = message.id.auth[0]
        if (authMessage && authMessage.connection_id) {
          state.socket.connectionId = authMessage.connection_id
          WS_DATA.authOnConnect = null
          WS_DATA.authSentDate = null

          console.debug(`Socket connectionId:${authMessage.connection_id} defined`)
          if (WS_SUBSCRIPTIONS.auth) {
            for (let item of Object.values(WS_SUBSCRIPTIONS.auth)) {
              if (item.callbackConfig) {
                item.callbackConfig.connectionId = authMessage.connection_id
                item.callback(item.callbackConfig)
              } else {
                item.callback(authMessage.connection_id)
              }
            }
            delete WS_SUBSCRIPTIONS.auth
          }
          callReconnectCallbacks(authMessage.connection_id)
          undefinedAuthError = false
        } else {
          for (let error of utils.errors(message.id.auth).items) {
            if (error.key === 'Unauthorized') {
              console.warn('WS: Expired session, going to login')
              store.dispatch('logout')
              undefinedAuthError = false
            }
          }
        }

        if (undefinedAuthError) {
          console.error('Failed to auth WS', message.id.auth)
        }
      } else if (message.id.anonymous_auth) {
        const info = message.id.anonymous_auth[0]
        if (info && info.connection_id) {
          state.socket.connectionId = info.connection_id
          WS_DATA.authOnConnect = null
          WS_DATA.authSentDate = null
          console.debug(`Socket anonymous_auth connectionId: ${info.connection_id} defined`)
        }
      }
    }

    if (message.notifications) {
      if (message.notifications.readNotification) {
        for (let message of message.notifications.readNotification) {
          removeUnreadNotificationCore(state, message.key)
        }
      }
      if (message.notifications.newNotification) {
        for (let notification of message.notifications.newNotification) {
          if (notification.unread) {
            state.session.unreadNotifications.unshift(notification)
            state.session.unreadNotificationsCount += 1
            if (notification.application) {
              for (let app of state.session.apps) {
                if (app.code == notification.application) {
                  app.unread += 1
                  break
                }
              }
            }
          }
        }
      }
    }

    if (message.chat && message.chat.newMessage) {
      for (let wsMessage of message.chat.newMessage) {
        if (wsMessage.channel_key == state.session.activeChatKey) {
          continue
        }

        let messageRef
        for (let idx in state.session.unreadChatMessages) {
          const channelMessage = state.session.unreadChatMessages[idx]
          if (channelMessage.channel_key == wsMessage.channel_key) {
            state.session.unreadChatMessages.splice(idx, 1)
            messageRef = channelMessage
            break
          }
        }

        if (!messageRef) messageRef = { unread: 0 }
        messageRef.key = wsMessage.message.key
        messageRef.channel_key = wsMessage.channel_key
        messageRef.user = wsMessage.user
        messageRef.message = wsMessage.message.message
        messageRef.created_date = wsMessage.message.created_date
        messageRef.unread += 1

        state.session.unreadChatMessages.unshift(messageRef)
        state.session.unreadChatMessagesCount += 1

        var icon = ServiceStorage.getUserImageViewUrl(messageRef.user, 'medium')
        var cleanMessage = utils.stripHTML(messageRef.message)
        var htmlIfIcon = ''

        var notificationOptions = {
          body: cleanMessage,
        }

        // Setupo icon if definied
        if (icon) {
          notificationOptions.icon = icon
          htmlIfIcon = `
            <div class="w-10 h-10 overflow-hidden rounded-lg shadow flex-shrink-0">
              <img src="${icon}">
            </div>`
        }

        // Native notifications
        Vue.prototype.$notification.show(`${messageRef.user.name} escreveu:`, notificationOptions, {})

        // Buefy notifications
        Notification.open({
          duration: 5000,
          type: 'is-dark',
          message: `
          <div class="flex md:gap-4 max-w-xs items-start">
            ${htmlIfIcon}
            <div class="flex flex-col">
              <div class="text-xs opacity-70 text">
                ${messageRef.user.name} escreveu:
              </div>
              <div class="text-sm line-clamp-3">
                ${cleanMessage}
              </div>
            </div>
          </div>
          `,
          position: 'is-top-right',
          queue: false,
        })
      }
    }

    //Listen to form messages
    if (message.forms) {
      if (message.forms.instanceUpdate) {
        console.log('new message form: instance update')
        state.formUpdates = message.forms.instanceUpdate
      }

      console.log('new message form', message.forms)
      let messages = Object.keys(message.forms).map(key => {
        console.log(message.forms[key])
        if (message.forms[key]) {
          return message.forms[key].map(item => {
            item.type = key
            return item
          })
        } else {
          return []
        }
      })

      if (state.session.unreadExamWsMessages.length > 100) {
        //purge messages first 75 messages
        state.session.unreadExamWsMessages.splice(0, 75)
      }

      state.session.unreadExamWsMessages = state.session.unreadExamWsMessages.concat([].concat.apply([], messages))
      //console.log('processed message: ', messages)
      //.push()
    }

    for (const [key, messages] of Object.entries(message)) {
      const callbackItems = WS_SUBSCRIPTIONS[key]
      if (callbackItems) {
        for (let item of Object.values(callbackItems)) {
          if (item.callbackConfig) {
            item.callbackConfig.messages = messages
            item.callback(item.callbackConfig, state.socket.connectionId)
          } else {
            console.log('Callback', item, messages, state.socket.connectionId)
            item.callback(messages, state.socket.connectionId)
          }
        }
      }
    }
  },

  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info('WS reconnect', count)
  },

  SOCKET_RECONNECT_ERROR(state, event) {
    console.error('WS reconnect error', event)
  },

  sendWSMessage(state, message) {
    if (WS_DATA.isConnected) {
      Vue.prototype.$socket.sendObj(message)
      console.debug('Socket message sent', message)
    } else if (!state.socket.queue) {
      state.socket.queue = [message]
    } else {
      state.socket.queue.push(message)
    }
  },

  setConnectedUsers(state, { page, users }) {
    if (!state.connectedUsers) {
      state.connectedUsers = {}
    }
    Vue.set(state.connectedUsers, page, users)
  },
}
