const LZWCompress = {
  encoder: function() {
    let map = new Map()
    let output = []
    let phrase = null
    let code = 256

    return {
      feed(value) {
        if (!value) return

        let data = (value + '').split('')

        let startAt = 1
        if (phrase === null) phrase = data[0]
        else startAt = 0

        for (let i = startAt; i < data.length; i++) {
          let currChar = data[i]
          if (map.has(phrase + currChar)) {
            phrase += currChar
          } else {
            let outputChar = phrase.length > 1 ? map.get(phrase) : phrase.codePointAt(0)
            output.push(String.fromCodePoint(outputChar))

            map.set(phrase + currChar, code)
            code++
            if (code === 0xd800) code = 0xe000

            phrase = currChar
          }
        }
      },
      size() {
        return output.length
      },
      end() {
        let data = ''
        if (output.length) {
          let outputChar = phrase.length > 1 ? map.get(phrase) : phrase.codePointAt(0)
          output.push(String.fromCodePoint(outputChar))
          data = output.join('')
        }

        map = new Map()
        output = []
        phrase = null
        code = 256
        return data
      },
    }
  },

  decode: function(value) {
    const dict = new Map()
    const data = Array.from(value + '')
    var currChar = data[0]
    var oldPhrase = currChar
    var out = [currChar]
    var code = 256
    var phrase

    for (var i = 1; i < data.length; i++) {
      var currCode = data[i].codePointAt(0)
      if (currCode < 256) phrase = data[i]
      else phrase = dict.has(currCode) ? dict.get(currCode) : oldPhrase + currChar
      out.push(phrase)

      var cp = phrase.codePointAt(0)
      currChar = String.fromCodePoint(cp)
      dict.set(code, oldPhrase + currChar)
      code++
      if (code === 0xd800) code = 0xe000

      oldPhrase = phrase
    }

    return out.join('')
  },
}

export default LZWCompress
