<script>
export default {
  data() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
    }
  },

  mounted() {
    this.setViewHeight()
    window.addEventListener('resize', () => {
      this.setViewHeight()
    })
    window.addEventListener('offline', () => {
      this.$notification.show(
        'Ocorreu uma falha de ligação ao servidor',
        {
          body: `A sua ligação à internet não parece estar a funcionar.
          Por favor, verifique a sua ligação e tente novamente.`,
        },
        {}
      )
      this.$buefy.dialog.alert({
        title: 'Falha na ligação',
        message: `A sua ligação à internet não parece estar a funcionar.
          Por favor, verifique a sua ligação e tente novamente.`,
        type: 'is-dark',
        confirmText: 'Recarregar',
        ariaRole: 'alertdialog',
        canCancel: false,
        onConfirm: () => this.$router.go(),
      })
    })
  },

  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    setViewHeight: function() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
}
</script>
