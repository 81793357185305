<template>
  <div class="w-full h-full">
    <DynamicScroller
      v-if="notifications.length"
      :min-item-size="54"
      :items="notifications"
      :buffer="50"
      key-field="key"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.title]" :data-index="index">
          <div
            class="rounded-xl bg-white hover:shadow group"
            :class="{
              'pt-2 px-1 mb-1': type == 'minimal',
              'px-2 pt-2.5 pb-1 flex flex-col gap-1 mb-2': type == 'full',
            }"
          >
            <div class="px-1 flex gap-2">
              <div class="cursor-pointer" @click="goToNotification(item.key, !item.with_redirect)">
                <svg
                  v-if="getAppIcon(item) == 'default'"
                  class="fill-current w-9 h-9 rounded-lg p-2 bg-gray-900 text-white shadow-md"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
                  />
                </svg>
                <div
                  v-else
                  class="w-9 h-9 overflow-hidden rounded-lg flex items-center justify-center object-cover shadow-md"
                >
                  <img :src="getAppIcon(item)" />
                </div>
              </div>
              <div class="flex-1 flex flex-col">
                <div class="flex justify-between text-gray-400 gap-1">
                  <div class="font-medium text-xs uppercase">{{ getAppName(item) }}</div>
                  <div class="text-xs text-gray-500">{{ item.created_date | humanDateTimePT }}</div>
                </div>
                <div class="w-full">
                  <v-clamp
                    :class="{
                      'font-bold': item.unread && view !== 'unread',
                      'font-semibold': item.unread && view === 'unread',
                    }"
                    class="text-sm text-gray-800 cursor-pointer text-left"
                    :max-lines="5"
                    :max-height="250"
                    @click.native="goToNotification(item.key, !item.with_redirect)"
                    >{{ item.title }}</v-clamp
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center gap-2 group-hover:opacity-100 opacity-40">
              <div class="flex-1 flex items-center gap-4">
                <fw-button
                  v-if="item.unread && item.type != 'acceptance'"
                  size="xs"
                  type="link-muted"
                  class="flex items-center gap-1"
                  @click.native="update(item, 'read')"
                >
                  <svg
                    class="fill-current w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                  </svg>
                  {{ $t('markAsRead') }}
                </fw-button>
                <fw-button
                  v-if="view != 'unread' && view != 'archived' && (!item.unread || item.type != 'acceptance')"
                  size="xs"
                  type="link-muted"
                  class="flex items-center gap-1"
                  @click.native="update(item, 'archive')"
                >
                  <fw-icon-archive class="w-4 h-4" />
                  {{ $t('archive') }}
                </fw-button>
                <fw-button
                  v-if="view == 'archived'"
                  size="xs"
                  type="link-muted"
                  class="flex items-center gap-1"
                  @click.native="update(item, 'unarchive')"
                >
                  <fw-icon-unarchive class="w-4 h-4" />
                  {{ $t('moveToInbox') }}
                </fw-button>
                <div v-if="item.unread && item.type == 'acceptance'" class="font-bold text-sm px-2">
                  {{ $t('awaitsAcceptance') }}
                </div>
              </div>
              <fw-button size="xs" type="link" @click.native="goToNotification(item.key, !item.with_redirect)">{{
                $t('open')
              }}</fw-button>
            </div>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <div v-else class="flex flex-col text-sm text-gray-400 items-center font-medium justify-center mx-auto py-5">
      <fw-icon-sun class="w-16 h-16 mb-3 opacity-50" />
      <div>{{ $t('noNotifications') }}.</div>
    </div>
    <b-modal
      v-if="activeNotification"
      :active="isDetailsModalOpen"
      :width="500"
      scroll="keep"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      has-modal-card
      custom-class="z-100"
      @close="closeDetailsModal"
    >
      <div class="modal-card" style="width: auto; min-width: 360px">
        <div class="modal-card-body">
          <div class="px-1 flex gap-2 items-center text-sm">
            <div>
              <svg
                v-if="getAppIcon(activeNotification) == 'default'"
                class="fill-current w-12 h-12 md:w-14 md:h-14 rounded-lg p-2 lg:p-4 bg-gray-900 text-white shadow-md"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
                />
              </svg>
              <div
                v-else
                class="
                  w-12
                  h-12
                  md:w-14 md:h-14
                  overflow-hidden
                  rounded-lg
                  flex
                  items-center
                  justify-center
                  object-cover
                  shadow-md
                "
              >
                <img :src="getAppIcon(activeNotification)" />
              </div>
            </div>
            <div class="flex-1">
              <fw-label marginless>{{ $t('notification') }}</fw-label>
              <div class="font-bold uppercase">{{ getAppName(activeNotification) }}</div>
            </div>
            <div class="text-gray-500">{{ activeNotification.created_date | formatDateTime }}</div>
          </div>
          <div
            class="mt-7 mb-1 text-gray-800 font-bold text-center p-5 bg-gray-100 rounded-lg"
            v-html="activeNotification.message"
          />

          <div v-if="activeNotification.type == 'acceptance' && !activeNotification.date" class="mt-2">
            <fw-button
              type="primary"
              size="md"
              expanded
              class="flex items-center justify-center gap-1"
              @click.native="update(activeNotification, 'accept')"
            >
              {{ $t('accept') }}
            </fw-button>
          </div>
          <div v-else class="flex w-full items-center gap-2 justify-center mb-7">
            <fw-button
              v-if="!activeNotification.date"
              type="link-muted"
              class="flex items-center gap-1"
              @click.native="update(activeNotification, 'read')"
            >
              <fw-icon-check class="w-5 h-5" />
              {{ $t('markAsRead') }}
            </fw-button>
            <fw-button
              v-if="!activeNotification.archived_date"
              type="link-muted"
              class="flex items-center gap-1"
              @click.native="update(activeNotification, 'archive')"
            >
              <fw-icon-archive class="w-5 h-5" />
              {{ $t('archive') }}
            </fw-button>
            <fw-button
              v-if="activeNotification.archived_date"
              type="link-muted"
              class="flex items-center gap-1"
              @click.native="update(activeNotification, 'unarchive')"
            >
              <fw-icon-unarchive class="w-5 h-5" />
              {{ $t('moveToInbox') }}
            </fw-button>
          </div>

          <div class="flex justify-center items-center mt-2">
            <fw-button
              :type="!activeNotification.date && activeNotification.type == 'acceptance' ? 'link-muted' : 'black'"
              :expanded="true"
              @click.native="closeDetailsModal"
              >{{ $t('close') }}</fw-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import ServiceNotifications from '@/fw-modules/fw-core-vue/notifications/services/ServiceNotifications'
import config from '@/fw-modules/fw-core-vue/config.js'
export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },

  props: {
    view: String,
    notifications: Array,
    type: {
      type: String,
      default: 'minimal',
    },
  },

  data() {
    return {
      isDetailsModalOpen: false,
      activeNotification: null,
    }
  },

  methods: {
    closeDetailsModal() {
      if (this.activeNotification && this.view == 'unread') {
        this.$store.dispatch('removeUnreadNotification', this.activeNotification.key)
      }

      this.activeNotification = null
      this.isDetailsModalOpen = false
    },

    async goToNotification(key, forceModal = true) {
      this.activeNotification = await ServiceNotifications.get(key, this.view == 'unread')
      //console.log(this.activeNotification)
      if (
        this.activeNotification.redirect_url !== null &&
        typeof this.activeNotification.redirect_url === 'string' &&
        this.activeNotification.redirect_url.length > 0 &&
        forceModal === false
      ) {
        const redirectUrl = this.activeNotification.redirect_url
        let hostname_withoutprotocol = redirectUrl.startsWith('http://') ? redirectUrl.substr(7) : redirectUrl.substr(8)
        //console.log('hostname_withoutprotocol', hostname_withoutprotocol)
        let parsed_host = hostname_withoutprotocol
          .split('/')[0]
          .split('.')
          .slice(-2)
          .join('.')
          .toLowerCase()
        //console.log('parsed_host', parsed_host)
        if (config.secureDomains.includes(parsed_host)) {
          window.open(this.activeNotification.redirect_url, '_self')
        } else {
          console.error(this.activeNotification.redirect_url + ' is not a secure domain')
        }
      } else {
        this.isDetailsModalOpen = true
      }
    },

    getAppName(notification) {
      if (!notification.application_title) {
        if (!notification.application) {
          notification.application_title = 'Sistema'
        } else {
          for (let app of this.$store.getters.getApps) {
            if (app.code == notification.application) {
              notification.application_title = app.title
              break
            }
          }
          if (!notification.application_title) {
            notification.application_title = notification.application
          }
        }
      }
      return notification.application_title
    },
    getAppIcon(notification) {
      if (!notification.application_icon) {
        notification.application_icon = 'default'
        if (notification.application) {
          for (let app of this.$store.getters.getApps) {
            if (app.code == notification.application) {
              if (app.icon) {
                notification.application_icon = app.icon.url.replace('{SIZE}', '65')
              }
              break
            }
          }
        }
      }
      return notification.application_icon
    },

    async update(notification, action) {
      const updatedNotification = await ServiceNotifications.update(notification.key, action)
      if (action != 'unarchive') {
        this.$store.dispatch('removeUnreadNotification', notification.key)
        if (action == 'read' || action == 'accept') {
          notification.unread = false
          notification.date = updatedNotification.date
        } else {
          notification.unread = false
          notification.date = updatedNotification.date
          notification.archived_date = updatedNotification.archived_date
        }
      }
      if (this.isDetailsModalOpen) {
        this.closeDetailsModal()
      }

      if ((action == 'archive' && this.view == 'inbox') || (action == 'unarchive' && this.view == 'archived')) {
        this.$emit('remove-from-list', notification.key)
      }
    },
  },
}
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease-out;
}
.list-enter-active {
  opacity: 0.5;
}
.list-leave-to {
  transform: translateY(-300%);
  opacity: 0;
}
.list-leave-active {
  position: absolute;
}
</style>

<i18n>
{
  "pt": {
    "notification": "Notificação",
    "markAsRead": "Marcar como lida",
    "archive": "Arquivar",
    "moveToInbox": "Mover para inbox",
    "awaitsAcceptance": "Aguarda aceitação",
    "open": "Abrir",
    "notNotifications":"Sem notificações",
    "accept": "Aceitar",
    "close": "Fechar"
  },
  "en": {
    "notification": "Notification",
    "markAsRead": "Mark as read",
    "archive": "Archive",
    "moveToInbox": "Move to inbox",
    "awaitsAcceptance": "Awaits acceptance",
    "open": "Open",
    "notNotifications":"No notifications",
    "accept": "Accept",
    "close": "Close"
  }
}
</i18n>
