<template>
  <div
    class="flex items-center gap-1"
    :class="[
      {
        'mt-2 mb-4': !marginless,
        uppercase: uppercase,
        'error-orange text-sm text-opacity-90 font-semibold': error,
        'text-gray-500 text-xs font-normal': !error,
      },
    ]"
  >
    <fw-icon-error-warning v-if="error" class="w-5 h-5" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    marginless: Boolean,
    uppercase: Boolean,
    error: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.error-orange {
  color: rgb(241, 95, 31);
}
</style>
