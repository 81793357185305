<template>
  <LayoutDefault
    :main-footer="false"
    :main-sidebar="false"
    :header-session-nav="false"
    :dark="false"
    :full="true"
    :force-extended-app-logo="true"
  >
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="centered-container">
        <fw-panel v-if="!result" :title="$t('titleRecoverPassword')" custom-class="bg-white p-10 w-96">
          <fw-heading class="mb-5">{{ $t('titleNewPassword') }}</fw-heading>
          <form method="post" autocomplete="off" @submit.prevent="doUpdatePassword">
            <div class="text-sm text-gray-500 mb-3">
              {{ $t('messageProvideSafePassword') }}
            </div>

            <b-field
              :label="$t('labelPasswordField')"
              label-for="password"
              :type="{ 'is-danger': $v.password.$error }"
              :message="$v.password.$error ? $t('messagePasswordRequired') : ''"
            >
              <b-input
                v-model.trim="$v.password.$model"
                :placeholder="$t('messagePasswordPlaceholder')"
                :disabled="validating"
                type="password"
                name="password"
                custom-class="p-6"
              />
            </b-field>
            <password v-model="password" :strength-meter-only="true" secure-length="8" />

            <b-field
              :label="$t('labelConfirmPasswordField')"
              label-for="confirmPassword"
              :type="{ 'is-danger': $v.confirmPassword.$error }"
              :message="
                $v.confirmPassword.$error
                  ? !$v.confirmPassword.sameAsPassword
                    ? $t('messagePasswordSameAs')
                    : $t('messagePasswordRequired')
                  : ''
              "
            >
              <b-input
                v-model.trim="$v.confirmPassword.$model"
                :placeholder="$t('labelConfirmPasswordPlaceholder')"
                :disabled="validating"
                type="password"
                name="confirmPassword"
                custom-class="p-6"
              />
            </b-field>

            <!-- Errors -->
            <div v-if="error" class="my-5">
              <div class="font-bold text-sm mb-1 text-red-800">{{ $t('errorFound') }}</div>
              <div class="p-3 bg-red-50 rounded-lg mb-5 text-red-800 font-medium">
                <div class="max-w-sm mx-auto text-center">
                  <div v-if="error == 'MaxAttempts'">
                    <div>{{ $t('errorMaxAttempts') }}</div>
                  </div>
                  <div v-if="error == 'Required'">
                    <div>{{ $t('errorRequired') }}</div>
                  </div>
                  <div v-else-if="error == 'InvalidActivateToken'">
                    <div>{{ $t('errorInvalidActivateToken') }}</div>
                  </div>
                  <div v-else>
                    <div class="text-sm font-bold p-2">Error: {{ error }}</div>
                  </div>
                </div>
              </div>
            </div>

            <b-field class="mt-5">
              <b-button
                expanded
                :disabled="!isUpdateAllowed"
                :loading="validating"
                :type="isFormReady ? 'is-primary' : 'is-dark'"
                size="is-medium"
                @click="doUpdatePassword"
                >{{ $t('linkChangePassword') }}</b-button
              >
            </b-field>
          </form>
          <div class="mt-5 text-center">
            <fw-button type="link-muted" size="sm" @click.native="$router.push({ name: 'home' })">{{
              $t('messageBackToLogin')
            }}</fw-button>
          </div>
        </fw-panel>
        <div v-else class="w-80">
          <div class="rounded-full mx-auto w-16 h-16 bg-primary flex items-center justify-center m-5">
            <fw-icon-check class="fill-current h-8 w-8 text-white"></fw-icon-check>
          </div>
          <fw-heading size="h3" class="text-center mb-4 text-primary">{{ $t('messageUpdatedPassword') }}</fw-heading>
          <div class="text-center">
            <fw-button type="primary" expanded size="sm" @click.native="$router.push({ name: 'home' })">{{
              $t('linkGoToLogin')
            }}</fw-button>
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import Password from 'vue-password-strength-meter'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/interface/components/header/HeaderLanguagePanel'
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'

export default {
  name: 'ExternalAccountChangePassword',

  components: {
    LayoutDefault,
    Password,
    HeaderLanguagePanel,
  },

  data() {
    return {
      validating: false,
      password: '',
      confirmPassword: '',
      error: false,
      result: false,
    }
  },

  validations: {
    password: { required, min: minLength(8) },
    confirmPassword: { required, min: minLength(8), sameAsPassword: sameAs('password') },
  },

  computed: {
    token() {
      return this.$route.query.t ? this.$route.query.t : null
    },
    isUpdateAllowed() {
      return !this.$v.$invalid && !this.validating
    },
    isFormReady() {
      return !this.$v.$invalid && !this.validating && !this.error
    },
  },

  created() {
    if (!this.token) {
      this.$router.push({ name: 'home' })
    }
  },

  methods: {
    async doUpdatePassword() {
      this.$v.$touch()

      // Avoid pressing when doing something
      if (this.validating) return

      this.validating = true

      if (!this.$v.$invalid) {
        this.error = false
        this.validating = true

        try {
          this.result = await ServiceAuth.changePswExternalAccount(this.token, this.password, process.env.VUE_APP_KEY)
        } catch (error) {
          this.error = utils.errors(error).getKey() || 'Undefined'
          console.warn(`login error: ${this.error}`, error)
        }
      }

      setTimeout(() => {
        this.validating = false
      }, 500)
    },
  },
}
</script>

<i18n>
 {
  "pt": {
    "messageProvideSafePassword": "Defina uma password segura, com o mínimo de 8 caracteres, 3 dos quais devem ser letras e um dígito. A password será validada novamente após submeter o formulário de registo.",
    "titleRecoverPassword": "Conta externa UC",
    "titleNewPassword": "Nova password",
    "labelPasswordField": "Password",
    "messagePasswordRequired": "Password obrigatória",
    "messagePasswordPlaceholder": "Defina uma password segura",
    "labelConfirmPasswordField": "Confirmar",
    "messagePasswordSameAs": "Confirme a nova password",
    "labelConfirmPasswordPlaceholder": "",
    "errorFound": "Ocorreu um erro",
    "errorMaxAttempts": "O limite de tentativas foi atingido. Por favor, tente mais tarde.",
    "errorRequired": "Obrigatório",
    "errorInvalidActivateToken": "O código de validação não é válido",
    "linkChangePassword": "Alterar password",
    "messageBackToLogin": "voltar à página de autenticação",
    "messageUpdatedPassword": "Password atualizada com sucesso",
    "linkGoToLogin": "Aceder à página de autenticação"
  },
  "en": {
    "messageProvideSafePassword": "Defina uma password segura, com o mínimo de 8 caracteres, 3 dos quais devem ser letras e um dígito. A password será validada novamente após submeter o formulário de registo.",
    "titleRecoverPassword": "Conta externa UC",
    "titleNewPassword": "Nova password",
    "labelPasswordField": "Password",
    "messagePasswordRequired": "Password obrigatória",
    "messagePasswordPlaceholder": "Defina uma password segura",
    "labelConfirmPasswordField": "Confirmar",
    "messagePasswordSameAs": "Confirme a nova password",
    "labelConfirmPasswordPlaceholder": "",
    "errorFound": "Ocorreu um erro",
    "errorMaxAttempts": "O limite de tentativas foi atingido. Por favor, tente mais tarde.",
    "errorRequired": "Obrigatório",
    "errorInvalidActivateToken": "O código de validação não é válido",
    "linkChangePassword": "Alterar password",
    "messageBackToLogin": "voltar à página de autenticação",
    "messageUpdatedPassword": "Password atualizada com sucesso",
    "linkGoToLogin": "Aceder à página de autenticação"
  }
 }
</i18n>
