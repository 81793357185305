<template>
  <b-dropdown
    v-if="userLoggedIn"
    max-height="500"
    aria-role="list"
    position="is-bottom-left"
    class="block-header-notifications h-full dropdown-notifications"
  >
    <fw-button-dropdown
      slot="trigger"
      type="transparent"
      :label="$t('seeNotifications')"
      :chevron="false"
      :class="{ 'text-red-600': unread }"
    >
      <fw-icon-bell class="w-5 h-5" />
      <span
        v-if="unread"
        aria-hidden="true"
        :class="{ 'px-1': unread >= 10, 'px-2': unread < 10 }"
        class="absolute top-1 shadow-lg -right-1 py-0.5 text-xs font-bold text-white inline-block w-auto h-auto transform translate-x-1 -translate-y-1 bg-red-600 rounded-full dark:border-gray-800"
        >{{ unread }}</span
      >
    </fw-button-dropdown>

    <b-dropdown-item
      aria-role="menu-item"
      :focusable="false"
      custom
      class="pl-4 pr-2 flex justify-between gap-2 items-center z-20"
      :style="{
        boxShadow: isScrolling ? '0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 -1px 4px -1px rgba(0, 0, 0, 0.06)' : 'none',
      }"
    >
      <fw-heading size="h3" class="text-gray-500 mt-1">{{ $t('notifications') }}</fw-heading>
      <fw-button type="link" class="mt-0.5" size="sm" @click.native="$router.push('/notifications')">{{
        $t('seeAll')
      }}</fw-button>
    </b-dropdown-item>
    <b-dropdown-item :focusable="false" custom class="md:w-96 z-20" paddingless>
      <div ref="notificationsScroll" class="overflow-y-auto px-2" style="max-height: 450px">
        <BlockListNotifications
          v-if="notifications.length"
          :notifications="notifications"
          view="unread"
          class="w-full"
        />
        <div v-else class="text-base text-gray-400 text-center pt-24 pb-36">
          <fw-icon-smile class="opacity-70 h-14 w-14 mx-auto mb-2"></fw-icon-smile>

          <div>{{ $t('noUnread') }}</div>
          <fw-button
            class="mt-6 bg-gray-100 px-4 bg-opacity-90 h-9 justify-center hover:opacity-80 text-gray-700"
            type="link"
            size="sm"
            @click.native="$router.push('/notifications')"
            >{{ $t('seeOld') }}</fw-button
          >
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="notifications.length - acceptanceNotifications.length > 1"
      aria-role="menu-item"
      :focusable="false"
      custom
      class_edition="z-20"
      :style="{
        boxShadow:
          notifications.length - acceptanceNotifications.length > 4
            ? '0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 -1px 4px -1px rgba(0, 0, 0, 0.06)'
            : 'none',
      }"
    >
      <div class="flex justify-around py-2 gap-1">
        <fw-button
          type="link"
          size="sm"
          class="rounded-full bg-gray-50 bg-opacity-90 h-9 w-1/2 justify-center hover:opacity-80 text-gray-800"
          @click.native="markAll('archive')"
        >
          <fw-icon-archive class="h-5"></fw-icon-archive>
          <span>{{ $t('archiveAll') }}</span>
        </fw-button>
        <fw-button
          type="link"
          size="sm"
          class="rounded-full bg-teal-600 bg-opacity-10 h-9 w-1/2 justify-center hover:opacity-80 flex-shrink-0"
          @click.native="markAll('read')"
        >
          <fw-icon-check-all class="h-5"></fw-icon-check-all>
          <span>{{ $t('markAsRead') }}</span>
        </fw-button>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import BlockListNotifications from '@/fw-modules/fw-core-vue/notifications/components/blocks/BlockListNotifications'
import ServiceNotifications from '../../services/ServiceNotifications'

export default {
  components: {
    BlockListNotifications,
  },

  data: function() {
    return {
      isScrolling: false,
    }
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    unread() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    notifications() {
      return this.$store.getters.getUnreadNotifications
    },
    acceptanceNotifications() {
      return this.$store.getters.getUnreadNotificationsOfType('acceptance')
    },
  },

  mounted() {
    this.$refs.notificationsScroll.onscroll = () => {
      let distance =
        this.$refs.notificationsScroll.offsetTop -
        this.$refs.notificationsScroll.scrollTop +
        this.$refs.notificationsScroll.clientTop
      if (distance !== 0) {
        this.isScrolling = true
      } else {
        this.isScrolling = false
      }
    }
  },

  methods: {
    markAll(action) {
      let keys = this.notifications.map(obj => {
        return obj.key
      })
      try {
        ServiceNotifications.updateBulk(keys, action)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notifications": "Notificações",
    "seeNotifications": "Ver notificações",
    "seeAll": "Ver todas",
    "noUnread": "Sem notificações por ler",
    "seeOld": "Ver notificações antigas",
    "archiveAll": "Arquivar todas",
    "markAsRead": "Marcar como lidas"
  },
  "en": {
    "notifications": "Notifications",
    "seeNotifications": "See notifications",
    "seeAll": "See all",
    "noUnread": "No unread notifications",
    "seeOld": "View old notifications",
    "archiveAll": "Archive all",
    "markAsRead": "Mark as read"
  }
}
</i18n>
