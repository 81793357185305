const device = {
  isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  },

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  },

  isTablet() {
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      navigator.userAgent.toLowerCase()
    )
  },

  isiOS() {
    var iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
    if (navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
          return true
        }
      }
    }
    return false
  },

  enterFullscreen() {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen()
    } else if (document.body.mozRequestFullScreen) {
      /* Firefox */
      document.body.mozRequestFullScreen()
    } else if (document.body.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      document.body.webkitRequestFullscreen()
    } else if (document.body.msRequestFullscreen) {
      /* IE/Edge */
      document.body.msRequestFullscreen()
    }
  },

  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen()
    }
  },
}

export default device
