import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// Import Fw Icons
const requireComponent = require.context(
  '@/fw-modules/fw-core-vue/ui/components/icons',
  false,
  /Icon[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component('Fw' + componentName, componentConfig.default || componentConfig)
})

// FontAwesome - to be discontinued and replaced by FwIcons

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import '@/fw-modules/fw-core-vue/interface/assets/compiled/ucteacher-logo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ucteacher-short-logo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ucmeetings-logo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ucmeetings-short-logo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ucstudent-logo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ucstudent-short-logo'

import '@/fw-modules/fw-core-vue/interface/assets/compiled/bold'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/redo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/undo'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/hr'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/code'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/quote'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ul'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/ol'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/paragraph'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/italic'
import '@/fw-modules/fw-core-vue/interface/assets/compiled/underline'

import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons/faGlobeEurope'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faSadTear } from '@fortawesome/free-solid-svg-icons/faSadTear'
import { faSadCry } from '@fortawesome/free-solid-svg-icons/faSadCry'
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons/faHeartBroken'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons/faIdBadge'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube'
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons/faStarOfLife'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons/faCheckDouble'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons/faConciergeBell'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt'
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons/faPauseCircle'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faUserClock } from '@fortawesome/free-solid-svg-icons/faUserClock'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons/faCalendarPlus'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons/faVideoSlash'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList'
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar'
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faGripLines } from '@fortawesome/free-solid-svg-icons/faGripLines'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons/faGripHorizontal'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons/faExpandAlt'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons/faMicrophoneSlash'
import { faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons/faMicrophoneAlt'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt'
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons/faVolumeMute'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons/faVolumeUp'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt'
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie'
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge'
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faSmileBeam } from '@fortawesome/free-solid-svg-icons/faSmileBeam'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons/faCalendarDay'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle'
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload'
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons/faFileArchive'
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop'
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote'
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen'
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot'
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons/faDoorClosed'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons/faHandPaper'
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress'
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft'
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose'
import { faThumbtack } from '@fortawesome/free-solid-svg-icons/faThumbtack'
import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown'
import { faAdjust } from '@fortawesome/free-solid-svg-icons/faAdjust'
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding'
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons/faPhoneSlash'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare'
import { faInfinity } from '@fortawesome/free-solid-svg-icons/faInfinity'

library.add(
  faLockOpen,
  faExclamationTriangle,
  faUserCheck,
  faBuilding,
  faVolumeUp,
  faCog,
  faPhoneSlash,
  faAdjust,
  faCrown,
  faThumbtack,
  faChartPie,
  faCompress,
  faHandPaper,
  faDoorOpen,
  faDoorClosed,
  faMinusCircle,
  faChevronRight,
  faRobot,
  faBookOpen,
  faChevronLeft,
  faPlay,
  faBell,
  faStickyNote,
  faComments,
  faCommentSlash,
  faPlusCircle,
  faPlug,
  faDesktop,
  faFileArchive,
  faMicrophoneAlt,
  faMicrophoneSlash,
  faMicrophone,
  faTachometerAlt,
  faVolumeMute,
  faSyncAlt,
  faCloudUploadAlt,
  faFileUpload,
  faArchive,
  faTh,
  faAt,
  faTimes,
  faBan,
  faMinus,
  faCalendarDay,
  faSmileBeam,
  faHistory,
  faStopwatch,
  faThLarge,
  faUserTie,
  faCircle,
  faDotCircle,
  faBullhorn,
  faPaperPlane,
  faTv,
  faCommentAlt,
  faChartBar,
  faExpandAlt,
  faExpand,
  faEllipsisH,
  faGripHorizontal,
  faGripLines,
  faTrashAlt,
  faListAlt,
  faClipboard,
  faClipboardList,
  faPen,
  faUsers,
  faVideo,
  faVideoSlash,
  faCalendarCheck,
  faListUl,
  faLifeRing,
  faSignOutAlt,
  faLongArrowAltRight,
  faSearch,
  faLayerGroup,
  faChevronDown,
  faChevronUp,
  faGlobeEurope,
  faHome,
  faUserCircle,
  faCompass,
  faInfoCircle,
  faArrowLeft,
  faArrowRight,
  faSadTear,
  faSadCry,
  faHeartBroken,
  faUniversity,
  faIdBadge,
  faLongArrowAltLeft,
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faCheckSquare,
  faUserFriends,
  faBook,
  faInfoCircle,
  faQuestionCircle,
  faComment,
  faCube,
  faStarOfLife,
  faPlus,
  faSignOutAlt,
  faCheckDouble,
  faFilter,
  faConciergeBell,
  faExternalLinkAlt,
  faHashtag,
  faCloudDownloadAlt,
  faCubes,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faPlayCircle,
  faPauseCircle,
  faPause,
  faTimesCircle,
  faCheckCircle,
  faAngleLeft,
  faAngleRight,
  faUserClock,
  faFilePdf,
  faFileAlt,
  faImage,
  faLock,
  faUnlock,
  faFlagCheckered,
  faCheck,
  faCalendarPlus,
  faBoxOpen,
  faClock,
  faCheckCircle,
  faEdit,
  faPhone,
  faEnvelope,
  faMobile,
  faCaretDown,
  faCalendarAlt,
  faPowerOff,
  faAlignLeft,
  faWindowClose,
  faSquare,
  faInfinity
)
Vue.component('faicon', FontAwesomeIcon)
