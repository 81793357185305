<template>
  <div
    class="items-center inline-flex flex-shrink-0"
    :class="[
      {
        'bg-primary text-white': type === 'primary',
        'bg-primary bg-opacity-10 text-primary': type === 'light-primary',
        'bg-yellow-600 bg-opacity-10 text-yellow-600': type === 'light-orange',
        'bg-red-600 bg-opacity-10 text-red-600': type === 'light-danger',
        'border border-primary text-primary': type === 'border-primary',
        'border border-gray-500 text-gray-500': type === 'border-light',
        'border border-opacity-40 border-gray-800 text-gray-500 px-1.5 py-0.5 rounded': type === 'light-border-box',
        'border border-opacity-40 border-primary text-primary px-1.5 py-0.5 rounded':
          type === 'light-primary-border-box',
        'bg-yellow-500 text-white': type === 'orange',
        'bg-red-600 text-white': type === 'danger',
        'bg-gray-500 text-white': type === 'medium',
        'bg-gray-500 bg-opacity-50': type === 'light',
        'bg-white text-gray-800': type === 'white',
        'text-white bg-gray-800 bg-opacity-20 backdrop-blur-sm': type === 'dark-blur',
        'text-gray-800 bg-white bg-opacity-40 backdrop-blur-sm': type === 'white-blur',
        'text-primary bg-white bg-opacity-20 backdrop-blur-sm': type === 'primary-blur',
        'bg-gray-100 text-gray-600': type === 'xlight',
        'bg-gray-800 text-white': type === 'dark',
        'border text-gray-800 border-gray-800': type === 'bordered',
        'rounded-full': rounded && type !== 'light-border-box' && type !== 'light-primary-border-box',
        'rounded-md': !rounded,
        'min-h-7': size !== 'xs',
        'min-h-5': size === 'xs',
        'px-3 py-1 font-semibold': type !== 'light-border-box' && type !== 'light-primary-border-box',
      },
      `text-${size}`,
      customClass,
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'medium',
    },
    size: {
      type: String,
      default: 'xs',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    customClass: String,
  },
}
</script>
