<template>
  <div
    v-if="!isBrowserSafe || (isBrowserSafe && isBrowserSafe.showWarning)"
    class="text-center p-10 rounded-3xl border border-red-100 max-w-xs mx-auto my-5"
  >
    <h1 v-if="!isBrowserSafe" class="text-xl text-red-500 font-bold m-2">
      {{ $t('safeBrowsers.incompatibleTitle') }}
    </h1>
    <div class="text-sm text-red-500 font-medium">
      {{ browser.name }} / {{ $t('safeBrowsers.version') }} {{ browser.version }}
    </div>
    <div class="text-sm text-gray-800 font-semibold my-5">{{ $t('safeBrowsers.use') }}:</div>
    <div class="flex gap-5 text-sm justify-center items-center text-gray-600 font-semibold">
      <div class="p-2 bg-gray-100 rounded-xl">
        <a href="https://www.google.com/intl/pt-PT/chrome/" target="_blank">Chrome</a>
      </div>
      <div class="p-2 bg-gray-100 rounded-xl">
        <a href="https://www.microsoft.com/pt-pt/edge" target="_blank">Edge</a>
      </div>
      <div class="p-2 bg-gray-100 rounded-xl">
        <a href="https://www.mozilla.org/pt-PT/firefox/new/" target="_blank">Firefox</a>
      </div>
    </div>
  </div>
</template>

<script>
import { detect } from 'detect-browser'

export default {
  name: 'BlockBrowserSafe',

  props: {
    browserSafe: {
      type: Object,
    },
  },

  data() {
    return {
      browser: detect(),
      isBrowserSafe: null,
      safeBrowsers: [
        { name: 'chrome', version: 81, showWarning: false },
        { name: 'firefox', version: 79, showWarning: false },
        { name: 'edge-chromium', version: 84, showWarning: false },
        { name: 'opera', version: 13, showWarning: false },
        { name: 'crios', version: 85, showWarning: false }, // Chrome iPad
        { name: 'ios-webview', version: 605, showWarning: false }, // Firefox iPad
        { name: 'ios', version: 13, showWarning: false },
        { name: 'safari', version: 13, showWarning: false },
      ],
    }
  },

  watch: {
    isBrowserSafe() {
      this.$emit('update:browserSafe', this.isBrowserSafe)
    },
  },

  created() {
    this.isBrowserSafe = this.safeBrowsers.find(safe => {
      return safe.name == this.browser.name && parseInt(this.browser.version) >= safe.version
    })
    this.isSafari = this.browser.name === 'safari'
    this.isMobile = this.browser.isMobile
  },
}
</script>

<i18n>
{
  "en": {
    "safeBrowsers": {
      "incompatibleTitle": "Your browser is not compatible or is out of date...",
      "use": "To use the platform, please use one of the following updated browsers",
      "version": "version"
    }
  },
  "pt": {
    "safeBrowsers": {
      "incompatibleTitle": "O seu browser não é compatível ou está desatualizado...",
      "use": "Para utilizar a plataforma, por favor, utilize um dos seguintes browsers atualizados",
      "version": "versão"
    }
  }
}
</i18n>
