var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items-center inline-flex flex-shrink-0",class:[
    {
      'bg-primary text-white': _vm.type === 'primary',
      'bg-primary bg-opacity-10 text-primary': _vm.type === 'light-primary',
      'bg-yellow-600 bg-opacity-10 text-yellow-600': _vm.type === 'light-orange',
      'bg-red-600 bg-opacity-10 text-red-600': _vm.type === 'light-danger',
      'border border-primary text-primary': _vm.type === 'border-primary',
      'border border-gray-500 text-gray-500': _vm.type === 'border-light',
      'border border-opacity-40 border-gray-800 text-gray-500 px-1.5 py-0.5 rounded': _vm.type === 'light-border-box',
      'border border-opacity-40 border-primary text-primary px-1.5 py-0.5 rounded':
        _vm.type === 'light-primary-border-box',
      'bg-yellow-500 text-white': _vm.type === 'orange',
      'bg-red-600 text-white': _vm.type === 'danger',
      'bg-gray-500 text-white': _vm.type === 'medium',
      'bg-gray-500 bg-opacity-50': _vm.type === 'light',
      'bg-white text-gray-800': _vm.type === 'white',
      'text-white bg-gray-800 bg-opacity-20 backdrop-blur-sm': _vm.type === 'dark-blur',
      'text-gray-800 bg-white bg-opacity-40 backdrop-blur-sm': _vm.type === 'white-blur',
      'text-primary bg-white bg-opacity-20 backdrop-blur-sm': _vm.type === 'primary-blur',
      'bg-gray-100 text-gray-600': _vm.type === 'xlight',
      'bg-gray-800 text-white': _vm.type === 'dark',
      'border text-gray-800 border-gray-800': _vm.type === 'bordered',
      'rounded-full': _vm.rounded && _vm.type !== 'light-border-box' && _vm.type !== 'light-primary-border-box',
      'rounded-md': !_vm.rounded,
      'min-h-7': _vm.size !== 'xs',
      'min-h-5': _vm.size === 'xs',
      'px-3 py-1 font-semibold': _vm.type !== 'light-border-box' && _vm.type !== 'light-primary-border-box',
    },
    `text-${_vm.size}`,
    _vm.customClass,
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }