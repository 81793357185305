/* eslint-disable no-unused-vars */
import config from '@/fw-modules/fw-core-vue/config'
import coreutils from '@/fw-modules/fw-core-vue/coreutils'

const utilsId = {
  _parseDomain(email) {
    let emailDomain = email.toLowerCase().split('@')
    if (emailDomain.length <= 1) {
      return false
    }
    return emailDomain[1]
  },
  emailService(email) {
    let emailDomain = this._parseDomain(email)

    if (emailDomain.endsWith('uc.pt')) {
      return 'webmail UC'
    } else if (emailDomain.endsWith('gmail.com')) {
      return 'Gmail'
    } else if (emailDomain.endsWith('hotmail.com') || emailDomain.endsWith('outlook.com')) {
      return 'Outlook'
    } else {
      return emailDomain
    }
  },
  isInstitutionalEmail(email) {
    let emailDomain = this._parseDomain(email)
    return config.institutionalDomains.includes(emailDomain)
  },
  isExternalInstitutionalEmail(email) {
    let emailDomain = this._parseDomain(email)
    return !config.institutionalDomains.includes(emailDomain)
  },
  isAllowedEmail(email) {
    return config.loginRules(email)
  },
  canSignupEmail(email) {
    let emailDomain = this._parseDomain(email)
    return !config.institutionalDomains.includes(emailDomain)
  },
  institutionalDomainsString() {
    return `@${config.institutionalDomains.join(', @')}`
  },
}

export default Object.assign({}, coreutils, utilsId)
