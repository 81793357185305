import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlActivity
    return api
  },

  baseUrl() {
    return FwEnvConfig.apiUrlActivity
  },

  async getActivity(payload) {
    /*
    PAYLOAD ===
    "service": "apply",
    "page": 1,
    "start_date": "2022-01-01",
    "end_date": "2022-12-31",
    "reference_keys": {
    },
    "code": "",
    "context": "",
    "context_key": "",
    "by_user_key": "",
    "by_token_key": ""
    */
    const response = await this.base().post('/v1/activity', payload)
    console.log(response)
    return response.data
  },

  async saveLogs(logs = []) {
    /*
    LOG EXAMPLE: 
      {
          "context": "procedure",
          "context_key": "abc-123",
          "message": {
              "code": "created"
          },
          "metadata": {
              "endpoint": "/v1/apps/notifications",
              "method": "POST"
          },
          "reference_keys": {
              "NAME": "Tomas",
              "coisas": "cenas"
          },
          "level_name": "info"
      }

      level_name: "info" | "warning" | "error" | "debug" | "critical" | "fatal" 
    */
    const response = await this.base().post('/v1/log', {
      context: {
        service: process.env.VUE_APP_KEY + '-app',
        application: process.env.VUE_APP_KEY + '-app',
      },
      data: logs,
    })
    console.log(response)
    return response.data
  },
}
