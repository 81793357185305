var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.editor)?_c('bubble-menu',{staticClass:"bubble-menu",attrs:{"tippy-options":{ duration: 100 },"editor":_vm.editor}},[_c('button',{class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_vm._v(" Negrito ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_vm._v(" Itálico ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('strike') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleStrike()
          .run()}}},[_vm._v(" Sublinhado ")])]):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }