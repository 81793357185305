<template>
  <div>
    <fw-heading size="h3" class="text-gray-500 mb-2 flex items-center mt-10">
      {{ title }}
      <span class="text-xs ml-2">{{ filtered.length }}</span>
    </fw-heading>
    <div class="p-2 md:p-4 bg-white rounded-xl shadow-md">
      <div v-if="filters" class="pb-4 grid grid-flow-col auto-cols-min gap-4">
        <fw-button
          v-for="filter in filters"
          :key="filter.key"
          :type="activeFilterKey != filter.key ? 'link-muted' : 'link'"
          size="xs"
          class="min-w-max"
          @click.native="activeFilterKey = filter.key"
        >
          {{ filter.label }}
        </fw-button>
      </div>
      <div v-for="entry in filtered" :key="entry">
        <slot name="entry" :entry="entry"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    entries: {
      type: Array,
    },
    filters: {
      type: Array,
    },
    filterDefault: {
      type: String,
      default: null,
    },
    filterKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      activeFilterKey: null,
    }
  },

  computed: {
    filtered() {
      if (this.filters) {
        return this.entries.filter(entry => {
          return entry[this.filterKey] === this.activeFilterKey
        })
      } else {
        return this.entries
      }
    },
  },

  created() {
    this.activeFilterKey = this.filterDefault
  },
}
</script>
