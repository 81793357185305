import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import BaseRoutes from './routes'

import CoreRouter from '@/fw-modules/fw-core-vue/router'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'

Vue.use(VueRouter)

const routes = [].concat(BaseRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes,
})

router.beforeResolve((to, from, next) => {
  return CoreRouter.beforeResolve(to, from, next)
})
router.afterEach((to, from, next) => {
  return CoreRouter.afterEach(to, from, next)
})
router.beforeEach(async (to, from, next) => {
  let redirected = false
  if (to && to.name != 'logout') {
    const callbackApp = localStorage.getItem('force.callback.app')
    if (callbackApp) {
      const token = localStorage.getItem('session.token')
      if (token) {
        store.state.session.token = token
        redirected = await ServiceAuth.appAuth(callbackApp)
      }
    }
  }

  if (!redirected) {
    return CoreRouter.beforeEach(to, from, next)
  }
})

export default router
