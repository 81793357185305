/* eslint-disable no-console */
import Vue from 'vue'
import axios from 'axios'
import Nprogress from 'nprogress'
import { NotificationProgrammatic as Notification } from 'buefy'
import store from '@/store'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

let REQUEST_COUNT = 0

export default mainConfig => {
  if (!mainConfig) mainConfig = {}

  const headers = {
    accept: 'application/json',
    'Accept-Language': store.state.language || this.$i18n.locale,
  }
  if (!mainConfig.ignoreAppHeader) {
    headers.FwApp = `${process.env.VUE_APP_KEY}|${process.env.VUE_APP_VERSION}|${store.state.currentSessionKey}`
  }

  const instance = axios.create({
    baseURL: mainConfig.baseURL,
    withCredentials: false,
    headers: headers,
    transformRequest: mainConfig.transformRequest,
  })

  // Interceta o pedido
  instance.interceptors.request.use(
    function(config) {
      config.startAt = new Date()
      config.transactionID = REQUEST_COUNT
      REQUEST_COUNT += 1

      let message = `Request (${config.transactionID}) start ${config.method} ${config.baseURL + config.url}`
      if (config.data) {
        const messageData = utils.JSONSafeStringify(config.data)
        message += ` data:${messageData.length}`
        if (!mainConfig.ignoreDataLog) message += `:${messageData}`
      }
      console.debug(message)

      store.dispatch('setApiStatus', true)
      if (!config.quietly) Nprogress.start()
      return config
    },
    function(error) {
      Nprogress.done()
      return Promise.reject(error)
    }
  )

  // Interceta a resposta
  instance.interceptors.response.use(
    // Qualquer resposta com status code 200
    function(response) {
      const took = parseFloat((new Date() - response.config.startAt) / 1000).toFixed(3)
      let message = `Response (${response.config.transactionID}) done took:${took}`
      if (response.data) {
        const messageData = utils.JSONSafeStringify(response.data)
        message += ` data:${messageData.length}`
        if (!mainConfig.ignoreResponseLog && messageData.length <= 5000) message += `:${messageData}`
      }
      console.debug(message)

      Nprogress.done()
      return response
    },

    // Qualquer resposta com erro, onde o status code é diferente de 200
    function(error) {
      const config = error.config || {}

      const took = parseFloat((new Date() - config.startAt) / 1000).toFixed(3)
      const statusCode = error.response ? error.response.status : null
      let message = `Response (${config.transactionID}) done error:${statusCode} took:${took}`
      if (error.response && error.response.data) {
        const messageData = utils.JSONSafeStringify(error.response.data)
        message += ` data:${messageData.length}`
        if (!mainConfig.ignoreResponseLog && messageData.length <= 5000) message += `:${messageData}`
      }
      if (!statusCode || statusCode == 500) {
        console.error(message)
      } else {
        console.debug(message)
      }

      Nprogress.done()

      if (mainConfig.ignoreError) {
        return
      }

      if (!error.response) {
        // Erro de ligação - servidor não responde
        store.dispatch('setApiStatus', false)
      } else {
        if (error.response.status == 500) {
          Notification.open({
            duration: 5000,
            message: `<div class="text-md font-bold">Ups... ocorreu um erro interno.</div>
              <div class="text-sm">Por favor, tente mais tarde.</div>`,
            position: 'is-top-right',
            type: 'is-danger',
            queue: false,
            hasIcon: true,
          })
        } else if (error.response.status == 400) {
          if (utils.errors(error).exists('InvalidConnectionID')) {
            if (mainConfig.ignoreInvalidConnectionID) {
              return Promise.reject(error)
            }
            // Close socket active connection
            Vue.prototype.$socket.close()

            Notification.open({
              duration: 5000,
              message: `Ocorreu um erro no registo da sua sessão.<br>
                Por favor, tente novamente dentro de alguns segundos.`,
              position: 'is-top-right',
              type: 'is-danger',
              queue: false,
            })
          }
        } else if (error.response.status == 401 && process.env.VUE_APP_KEY !== 'ucplugin') {
          console.warn('Expired session, going to login')
          store.dispatch('logout')
        }

        // TODO add if (error.response.status == 403) {
        //   router.push('/forbidden')
        // }
      }

      return Promise.reject(error)
    }
  )

  return instance
}
