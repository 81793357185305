<template>
  <div
    v-if="(debug && debugmode) || !debug"
    class="panel-info"
    :class="[
      {
        'p-3 md:p-4 rounded-xl text-gray-600': (type && !clean) || empty,
        'text-gray-500': (type === 'light' && !clean) || empty,
        'text-gray-600': type === 'basic',
        'text-yellow-700': type === 'orange',
        'bg-yellow-700 bg-opacity-10': type === 'orange' && boxed,
        'p-2 rounded-xl': boxed,
        'text-gray-100 bg-gray-700 font-medium': type === 'dark',
        'text-gray-600 border border-gray-600 border-dashed font-medium': type === 'dotted',
        'text-gray-500': clean,
        'text-center': centered || empty,
        'bg-gray-400 bg-opacity-10': (type === 'light' && !clean) || empty,
      },
      `text-${size}`,
      customClass,
    ]"
  >
    <div v-if="debug || label" class="text-xs text-gray-500 uppercase mb-2 font-bold">
      {{ debug && !label ? 'Debug' : label }}
    </div>
    <div class="flex gap-2" :class="{ 'justify-center': centered }">
      <div v-if="icon && !debug">
        <fw-icon-info class="opacity-50 flex-shrink-0" :class="{ 'w-5 h-5': size != 'xs', 'w-4 h-4': size == 'xs' }" />
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'sm',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
    },
    empty: {
      type: Boolean,
    },
    boxed: {
      type: Boolean,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('debug'))
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-info + .panel-info {
  @apply mt-3;
}
</style>
