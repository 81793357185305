var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.debug && _vm.debugmode) || !_vm.debug)?_c('div',{staticClass:"panel-info",class:[
    {
      'p-3 md:p-4 rounded-xl text-gray-600': (_vm.type && !_vm.clean) || _vm.empty,
      'text-gray-500': (_vm.type === 'light' && !_vm.clean) || _vm.empty,
      'text-gray-600': _vm.type === 'basic',
      'text-yellow-700': _vm.type === 'orange',
      'bg-yellow-700 bg-opacity-10': _vm.type === 'orange' && _vm.boxed,
      'p-2 rounded-xl': _vm.boxed,
      'text-gray-100 bg-gray-700 font-medium': _vm.type === 'dark',
      'text-gray-600 border border-gray-600 border-dashed font-medium': _vm.type === 'dotted',
      'text-gray-500': _vm.clean,
      'text-center': _vm.centered || _vm.empty,
      'bg-gray-400 bg-opacity-10': (_vm.type === 'light' && !_vm.clean) || _vm.empty,
    },
    `text-${_vm.size}`,
    _vm.customClass,
  ]},[(_vm.debug || _vm.label)?_c('div',{staticClass:"text-xs text-gray-500 uppercase mb-2 font-bold"},[_vm._v(" "+_vm._s(_vm.debug && !_vm.label ? 'Debug' : _vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"flex gap-2",class:{ 'justify-center': _vm.centered }},[(_vm.icon && !_vm.debug)?_c('div',[_c('fw-icon-info',{staticClass:"opacity-50 flex-shrink-0",class:{ 'w-5 h-5': _vm.size != 'xs', 'w-4 h-4': _vm.size == 'xs' }})],1):_vm._e(),_c('div',[_vm._t("default")],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }