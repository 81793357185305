<template>
  <LayoutDefault>
    <template #main>
      <div class="w-full h-full flex flex-col gap-2 justify-center items-center max-w-prose mx-auto">
        <div class="p-5">
          <faicon icon="heart-broken" size="3x"></faicon>
        </div>
        <div class="text-xs">{{ $t('error404') }}</div>
        <h1 class="text-2xl">{{ $t('pageNotFound') }}</h1>
        <div class="p-5 text-sm text-gray-500 text-center">{{ $t('pageOrResourceNotFound') }}</div>
        <div class="has-margin-top-large">
          <b-button type="is-text is-small has-text-muted" @click="$router.go(-1)">{{
            $t('backToPreviousPage')
          }}</b-button>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'

export default {
  components: {
    LayoutDefault,
  },

  data() {
    if (process.env.VUE_APP_ERRORS_TO) {
      this.$router.push({ name: process.env.VUE_APP_ERRORS_TO })
    }
    return {}
  },
}
</script>

<i18n>
{
  "en": {
    "error404": "(404) Not found",
    "pageNotFound": "Page not found",
    "pageOrResourceNotFound": "The page or resource cannot be found.",
    "backToPreviousPage": "back to previous page"
  },
  "pt": {
    "error404": "(404) Not found",
    "pageNotFound": "Página não encontrada",
    "pageOrResourceNotFound": "A página ou recurso solicitado não foi encontrado.",
    "backToPreviousPage": "voltar à página anterior"
  }
}
</i18n>
