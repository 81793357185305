import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)
dayjs.tz.setDefault('Europe/Lisbon')

const Dates = {
  getDatesFromRange(startDate, endDate) {
    var dates = [],
      currentDate = new Date(startDate),
      addDays = function(days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
    while (currentDate <= new Date(endDate)) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  },

  now() {
    return dayjs().tz('Europe/Lisbon')
  },
  buildCore(value) {
    return dayjs(value)
  },
  build(value) {
    return dayjs.tz(String(value), 'Europe/Lisbon')
  },
  format(value, format) {
    return this.build(value).format(format)
  },
  formatCore(value, format) {
    return this.buildCore(value).format(format)
  },
  from(value, format) {
    return dayjs(value, format)
  },
  nowDiff(value, type) {
    return this.build(value).diff(this.now(), type)
  },
  toDate(value) {
    return this.build(value).toDate()
  },
  toDateString(value) {
    const month = String(value.getMonth() + 1).padStart(2, '0')
    const day = String(value.getDate()).padStart(2, '0')
    return `${value.getFullYear()}-${month}-${day}`
  },
  getCurrentWeekStartDay() {
    const date = Dates.now().startOf('week')
    if (date.format('ddd').toLowerCase() == 'sun') {
      return date.add(1, 'days')
    } else {
      return date
    }
  },
  secondsToTime(seconds) {
    let date = new Date(0)
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  },
  isToday(value) {
    return this.build(value).isToday()
  },
  isYesterday(value) {
    return this.build(value).isYesterday()
  },
  isTomorrow(value) {
    return this.build(value).isTomorrow()
  },
}

export default Dates
