<template>
  <LayoutDefault
    :main-footer="true"
    :main-sidebar="false"
    :header-session-nav="false"
    :dark="false"
    :full="true"
    :force-extended-app-logo="true"
  >
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div v-if="user && showProfile" class="max-w-prose mx-auto">
        <fw-heading size="h1">Perfil</fw-heading>
        <BlockAccountUpdate class="my-5" />
      </div>
      <div v-else-if="!user" class="centered-container">
        <BlockBrowserSafe :browser-safe.sync="isBrowserSafe" />
        <fw-panel
          v-if="isBrowserSafe"
          :title="$t('title.authentication')"
          :custom-class="allowPasswordless ? 'bg-white p-8 w-96 sm:w-full' : 'bg-white p-8 w-96'"
        >
          <div class="flex flex-row gap-2 md:gap-8">
            <div v-if="allowUserPassLogin" :class="{ 'w-72 md:w-80': allowPasswordless, 'w-full': !allowPasswordless }">
              <fw-heading class="mb-5 sm:mb-8">{{ $t('title.ucAccount') }}</fw-heading>

              <form method="post" @submit.prevent="doLogin">
                <b-field
                  :label="isInstitutionalEmail ? 'Email institucional' : 'Email'"
                  label-for="email"
                  :type="{ 'is-danger': $v.email.$error }"
                  :message="$v.email.$error ? $t('message.form.emailRequired') : ''"
                >
                  <b-input v-model="email" type="email" custom-class="p-6" @input="checkEmail"></b-input>
                </b-field>
                <b-field
                  label="Password"
                  label-for="password"
                  :type="{ 'is-danger': $v.password.$error }"
                  :message="$v.password.$error ? $t('message.form.passwordRequired') : ''"
                >
                  <b-input v-model="password" type="password" name="password" custom-class="p-6" />
                </b-field>

                <!-- Account type warning -->
                <div
                  v-if="!emailResent && !loginError && isExternalInstitutionalEmail"
                  class="text-sm font-medium mb-5 text-gray-500"
                >
                  <div v-if="false">
                    O email que indicou não é considerado uma conta institucional. Neste sentido e caso ainda não o
                    tenha feito, registe uma nova conta com este email para continuar. Caso já tenha registado e
                    validado a conta, por favor, prossiga com a autenticação. Em alternativa, caso possua, utilize a sua
                    conta institucional UC.
                  </div>
                  <div v-if="!isAllowedEmail">
                    O email que indicou não é considerado uma conta institucional nesta plataforma. Por favor, utilize a
                    sua conta institucional UC.
                  </div>
                </div>

                <!-- Login errors -->
                <div v-if="loginError || (!isAllowedEmail && isExternalInstitutionalEmail)" class="my-5">
                  <div class="font-bold text-sm mb-1 text-red-800">Ocorreu um erro:</div>
                  <div v-if="loginError" class="p-3 bg-red-50 rounded-lg mb-5 text-red-800 font-medium">
                    <div class="max-w-sm mx-auto text-center">
                      <div v-if="loginError == 'MaxAttempts'">
                        <div>Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.</div>
                      </div>
                      <div v-else-if="loginError == 'InvalidEmail'">
                        <div>O email indicado não parece ser válido.</div>
                      </div>
                      <div v-else-if="loginError == 'InvalidUCEmailOrPassword'">
                        <div>O email ou password não estão corretos ou a conta não existe.</div>
                      </div>
                      <div v-else-if="loginError == 'EmailNotActivated'">
                        <div>
                          <div>O email indicado corresponde a uma conta que ainda não foi ativada.</div>
                          <div class="text-sm mt-3">
                            Procure o email de validação que enviámos no momento do registo, na sua Inbox ou mesmo na
                            pasta SPAM.
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div>Não foi possível autenticar.</div>
                        <div class="text-sm font-bold p-2">Error: {{ loginError }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!loginError && !isAllowedEmail && isExternalInstitutionalEmail"
                    class="p-5 bg-red-50 rounded-lg mb-5 text-red-800 text-sm font-medium text-center"
                  >
                    <div class="max-w-sm mx-auto">
                      <div>
                        O domínio do email que indicou não permitirá uma autenticação válida nesta plataforma. Por
                        favor, utilize a sua conta institucional @uc.pt ou @student.uc.pt.
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="emailResent || (loginError && loginError == 'EmailNotActivated')"
                  class="mt-4 text-center font-medium text-gray-500 text-sm"
                >
                  <div v-if="!emailResent && !errorFound">
                    <fw-label marginless>{{ $t('message.noEmailArrived') }}?</fw-label>
                    <fw-button type="link-light" size="sm" @click.native="resendEmail">{{
                      $t('button.sendValidationEmailAgain')
                    }}</fw-button>
                  </div>
                  <div v-else-if="emailResent" class="font-bold text-primary p-2">
                    A mensagem de validação de conta foi enviada novamente.
                  </div>
                </div>

                <b-field class="mt-4">
                  <b-button
                    expanded
                    :disabled="!canLogin"
                    :type="canLogin && !loginError ? 'is-primary' : 'is-dark'"
                    size="is-medium"
                    @click="doLogin"
                    >{{ $t('button.login') }}</b-button
                  >
                </b-field>
                <b-field>
                  <b-checkbox v-model="keepSignIn" size="is-small">
                    <span class="text-sm">{{ $t('label.keepActiveSession') }}</span>
                  </b-checkbox>
                </b-field>
              </form>

              <div v-if="allowExternalAccountsRegister" class="mt-4 text-center font-medium text-gray-500">
                <router-link class="text-sm" :to="{ name: 'external-account-recover-password' }">{{
                  $t('link.recoverPassword')
                }}</router-link>
              </div>

              <div v-if="showAnonymousJoin" class="has-margin-top-large">
                <div class="has-text-small has-text-muted has-text-centered">
                  Não tem conta da Universidade de Coimbra?
                </div>
                <div class="has-margin-top">
                  <b-button
                    type="is-light"
                    expanded
                    @click="$router.push({ name: 'anonymous', params: { key: redirectToRoom } })"
                    >Entrar como convidado</b-button
                  >
                </div>
              </div>

              <div v-if="allowExternalAccountsRegister && !showAnonymousJoin" class="mt-10">
                <div v-if="!showAnonymousJoin" class="text-center font-semibold text-gray-700 text-md max-w-xs mx-auto">
                  Não tem conta institucional UC?
                </div>
                <div class="mt-4">
                  <b-button type="is-light" expanded @click="$router.push({ name: 'external-account-signup' })">{{
                    $t('button.registerAccount')
                  }}</b-button>
                </div>
              </div>
            </div>
            <div v-if="allowPasswordless">
              <BlockPasswordlessLogin :callback-app="callbackApp" />
            </div>
          </div>
          <div class="text-xs mt-10 text-gray-500 text-center border-t border-gray-200 pt-3 -mb-5">
            Se precisar de ajuda, por favor, envie-nos uma mensagem para
            <a :href="`mailto:${supportEmail}`" class="text-gray-500 font-semibold">{{ supportEmail }}</a>
          </div>
        </fw-panel>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import debounce from 'lodash/debounce'
import BlockPasswordlessLogin from '@/fw-modules/fw-core-vue/id/components/blocks/BlockPasswordlessLogin'
import BlockBrowserSafe from '@/fw-modules/fw-core-vue/id/components/blocks/BlockBrowserSafe'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'

import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/interface/components/header/HeaderLanguagePanel'
import BlockAccountUpdate from '@/fw-modules/fw-core-vue/id/components/blocks/BlockAccountUpdate'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import utilsId from '@/fw-modules/fw-core-vue/id/utils'

export default {
  name: 'Login',

  components: {
    LayoutDefault,
    BlockPasswordlessLogin,
    BlockBrowserSafe,
    HeaderLanguagePanel,
    BlockAccountUpdate,
  },

  data() {
    let callbackApp = localStorage.getItem('force.callback.app')
    if (!callbackApp) {
      callbackApp = this.$route.params.app
      if (callbackApp) {
        sessionStorage.setItem('callback.app', callbackApp)
      } else {
        callbackApp = sessionStorage.getItem('callback.app')
      }
    }

    return {
      email: '',
      password: '',
      keepSignIn: false,
      emailResent: null,
      loginError: '',
      errorFound: '',
      isBrowserSafe: null,
      isEmailNotAllowed: false,
      supportEmail: 'support@ucframework.pt',
      showAnonymousJoin: process.env.VUE_APP_KEY == 'ucmeetings',
      institutionalDomains: utilsId.institutionalDomainsString(),
      debugMode: Boolean(localStorage.getItem('debug')),
      redirectToRoom: false,
      countDown: 240,
      callbackApp: callbackApp,
      showProfile: true,
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(1) },
    keepSignIn: false,
  },

  computed: {
    user() {
      if (this.$store.getters.isLoggedIn) {
        const user = this.$store.getters.getUser
        if (user && user.key) {
          return user
        }
      }
      return null
    },

    allowExternalAccountsRegister() {
      return ['ucapply'].includes(this.callbackApp)
    },
    allowUserPassLogin() {
      return !['ucdigitaldeskX'].includes(this.callbackApp) // TODO
    },
    allowPasswordless() {
      if (!this.allowUserPassLogin) {
        return true
      }
      return (
        (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development' || this.debugMode) &&
        !this.isMobile
      )
    },

    isAllowedEmail() {
      return this.allowExternalAccountsRegister || this.isInstitutionalEmail
    },
    canLogin() {
      return true
    },
    isInstitutionalEmail() {
      if (this.email) return utilsId.isInstitutionalEmail(this.email)
      return false
    },
    isExternalInstitutionalEmail() {
      if (this.email) return utilsId.isExternalInstitutionalEmail(this.email)
      return false
    },
    isMobile() {
      return utils.isMobile()
    },
  },

  async created() {
    if (this.user && this.$route.params.app) {
      await ServiceAuth.appAuth(this.$route.params.app)
    }

    this.email = this.email
    this.checkEmail = debounce(() => {
      this.isEmailNotAllowed = !this.isAllowedEmail
    }, 1500)

    // Parse redirect (if found)
    const query = this.$route.query
    if (query && query.redirect && query.redirect.startsWith('/live/')) {
      const redirectParts = query.redirect.split('/')
      this.redirectToRoom = redirectParts.length ? redirectParts.slice(-1)[0] : false
    }
  },

  methods: {
    async doLogin() {
      this.$v.$touch()

      this.emailResent = null
      this.errorFound = null

      if (!this.$v.$invalid) {
        try {
          const response = await ServiceAuth.login(this.email, this.password, this.keepSignIn)
          if (response) {
            this.showProfile = false
            this.$store.commit('setNewLogin', response.data)
            sessionStorage.removeItem('callback.app')
            this.loginError = false

            const redirected = await ServiceAuth.appAuth(this.callbackApp)
            if (!redirected) {
              let redirect = this.$route.query.redirect || '/'
              if (redirect.includes('logout')) redirect = '/'
              this.$router.push(redirect)
              this.showProfile = true
            }

            return response
          }
        } catch (error) {
          this.loginError = utils.errors(error).getKey() || 'Undefined'
          console.warn(`login error: ${this.loginError}`, error)
          this.showProfile = true
        }
      }
    },

    async resendEmail() {
      this.errorFound = false
      let result = false

      try {
        result = await ServiceAuth.resendConfirmationEmail(this.email, process.env.VUE_APP_KEY)
      } catch (error) {
        this.errorFound = utils.errors(error).getKey() || 'Undefined'
        console.warn(`login error: ${this.errorFound}`, error)

        this.$buefy.snackbar.open({
          message: this.$t('error.tooManyAttempts'),
          type: 'is-danger',
          position: 'is-top-right',
          duration: 2000,
          queue: false,
        })
        return
      }
      this.emailResent = result
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": {
      "authentication": "Authentication",
      "ucAccount": "Your UC account"
    },
    "button": {
      "login": "Login",
      "registerAccount": "Sign up"
    },
    "label": {
      "keepActiveSession": "Keep me logged in"
    },
    "link": {
      "recoverPassword": "Recover password"
    },
    "message": {
      "toRecover": "Use <a href={link}>{link}</a> to recover the password of account <b>{{ email }}</b>.<br />",
      "validatingAccount": "Validating account...",
      "confirmedAccount": "Confirmed account",
      "checkEmail": "Check mailbox",
      "confirmEmailSent": "An email has been sent to you to confirm your account.",
      "resendConfirmEmail": "Resend confirmation email",
      "createAccountNotNeeded": "You don't need to create an account for",
      "notAllowedEmail": "To enter, use your institutional {application} account at the University of Coimbra",
      "form": {
        "passwordConfirmation": "Confirm your password",
        "passwordConfirmationPlaceholder": "Password confirmation",
        "passwordRequired": "Paswword is required",
        "passwordSameAs": "Password confirmation must be same as password",
        "emailRequired": "Email is required"
      }
    },
    "login": {
      "setPassword": "Enter password.",
      "invalidPassword": "Wrong email or password.",
      "open": "Signin",
      "openApplication": "Signin",
      "register": "Signup",
      "recover": "Reset account",
      "signout": "Signout",
      "keepSession": "Keep session alive",
      "security": "If you're on a public device, please use a incognito/private browser window and signout from your account when done.",
      "pwdForgot": "Forgot password?",
      "authFailed": "Not possible to signin. Please, try again later.",
      "maxAttempts": "Maximum number of attempts reached. Please, try again later.",
      "emailNotRegistered": "It looks like your e-mail address is not registered.",
      "toSignin": "To signin,",
      "goRegisterPage": "go to the registration page",
      "doRegister": "and create a new account (in case you don't have an UC account) or link your institutional address."
    },
    "signup": {
      "label": "Register account",
      "resetCode": "Reset code is invalid.",
      "maxAttemptsReached": "Maximum nunber of attempts reached.",
      "emailregistered": "The provided e-mail address is already registered. Please provide a different e-mail address to continue.",
      "difficulties": "In case of trouble signing in, please try to ",
      "recoverPassword": "reset the password",
      "contactSupport": "or create a support request.",
      "pwdMinChars": "Your password needs a minimum of 8 characters.",
      "pwdMinLetters": "Your password needs a minimum of 3 letters.",
      "registrationNotPossible": "Account not registered.",
      "registrationSuccessful": "Your account has been successfully registered!",
      "validateAccount": "Please check your e-mail account to validate your e-mail address.",
      "checkSpam": "If you're havinf trouble finding our message, please, check your SPAM folder.",
      "finishSignin": "Please, signin to finish your registration.",
      "institutionalAccounts": "Para contas institucionais, a operação de registo confirma apenas a sua intenção em aceder à plataforma.",
      "institutionalSignin": "Após submeter o seu registo, deverá entrar na aplicação e utilizar o email",
      "institutionalSigninPwd": "e password da sua conta institucional.",
      "institutionalNotAvailable": "O seu endereço pertence a um grupo de domínios institucional entre os quais esta plataforma não consegue garantir a autenticação.<br />Assim, para entrar, por favor, defina uma password e valide a sua conta através da mensagem de confirmação que iremos enviar após registo.",
      "pwdValid": "Provide a valid password",
      "pwdMatch": "Passwords don't match.",
      "pwdSafe": "Provide a safe password, with a minimum of 8 characters, 3 of them being letters.",
      "pwdConfirm": "Confirm password",
      "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
      "backToSignin": "back to signin page",
      "error": "There was an error processing your registration"
    }
  },
  "pt": {
    "title": {
      "authentication": "Autenticação",
      "ucAccount": "A sua conta UC"
    },
    "button": {
      "login": "Entrar",
      "registerAccount": "Registar conta"
    },
    "label": {
      "keepActiveSession": "Manter sessão ativa"
    },
    "link": {
      "recoverPassword": "Recuperar password"
    },
    "message": {
      "toRecover": "Use <a href={link}>{link}</a> to recover the password of account <b>{{ email }}</b>.<br />",
      "validatingAccount": "Validating account...",
      "confirmedAccount": "Confirmed account",
      "checkEmail": "Check mailbox",
      "confirmEmailSent": "An email has been sent to you to confirm your account.",
      "resendConfirmEmail": "Resend confirmation email",
      "createAccountNotNeeded": "You don't need to create an account for",
      "notAllowedEmail": "To enter, use your institutional {application} account at the University of Coimbra",
      "form": {
        "passwordConfirmation": "Confirme a palavra-passe",
        "passwordConfirmationPlaceholder": "Confirmação de palavra-passe",
        "passwordRequired": "A palavra-passe é obrigatória",
        "passwordSameAs": "A confirmação da palavra-passe tem que ser igual",
        "emailRequired": "O email é obrigatório"
      }
    },
    "login": {
      "setPassword": "Enter password.",
      "invalidPassword": "Wrong email or password.",
      "open": "Signin",
      "openApplication": "Signin",
      "register": "Signup",
      "recover": "Reset account",
      "signout": "Signout",
      "keepSession": "Keep session alive",
      "security": "If you're on a public device, please use a incognito/private browser window and signout from your account when done.",
      "pwdForgot": "Forgot password?",
      "authFailed": "Not possible to signin. Please, try again later.",
      "maxAttempts": "Maximum number of attempts reached. Please, try again later.",
      "emailNotRegistered": "It looks like your e-mail address is not registered.",
      "toSignin": "To signin,",
      "goRegisterPage": "go to the registration page",
      "doRegister": "and create a new account (in case you don't have an UC account) or link your institutional address."
    },
    "signup": {
      "label": "Register account",
      "resetCode": "Reset code is invalid.",
      "maxAttemptsReached": "Maximum nunber of attempts reached.",
      "emailregistered": "The provided e-mail address is already registered. Please provide a different e-mail address to continue.",
      "difficulties": "In case of trouble signing in, please try to ",
      "recoverPassword": "reset the password",
      "contactSupport": "or create a support request.",
      "pwdMinChars": "Your password needs a minimum of 8 characters.",
      "pwdMinLetters": "Your password needs a minimum of 3 letters.",
      "registrationNotPossible": "Account not registered.",
      "registrationSuccessful": "Your account has been successfully registered!",
      "validateAccount": "Please check your e-mail account to validate your e-mail address.",
      "checkSpam": "If you're havinf trouble finding our message, please, check your SPAM folder.",
      "finishSignin": "Please, signin to finish your registration.",
      "institutionalAccounts": "Para contas institucionais, a operação de registo confirma apenas a sua intenção em aceder à plataforma.",
      "institutionalSignin": "Após submeter o seu registo, deverá entrar na aplicação e utilizar o email",
      "institutionalSigninPwd": "e password da sua conta institucional.",
      "institutionalNotAvailable": "O seu endereço pertence a um grupo de domínios institucional entre os quais a plataforma ainda não consegue garantir a autenticação institucional.<br />Assim, para entrar na plataforma, por favor, defina uma password e valide a sua conta através da mensagem de confirmação que iremos enviar após registo.",
      "pwdValid": "Provide a valid password",
      "pwdMatch": "Passwords don't match.",
      "pwdSafe": "Provide a safe password, with a minimum of 8 characters, 3 of them being letters.",
      "pwdConfirm": "Confirm password",
      "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
      "backToSignin": "back to signin page",
      "error": "There was an error processing your registration"
    }
  }
}
</i18n>
