<template>
  <div class="flex items-center">
    <router-link v-if="backTo && !isMobile" class="overflow-hidden w-auto mr-2" :to="backTo || '/'">
      <span class="sr-only">Voltar à página anterior</span>
      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
      </svg>
    </router-link>
    <fw-heading size="h1" class="font-black flex-1">{{ title }}</fw-heading>
    <slot name="toolbar"></slot>
  </div>
</template>

<script>
export default {
  props: {
    // Back to page
    // Show arrow to return to previous page
    backTo: {
      type: String,
      default: null,
    },

    // Page title - on mobile, set on the header
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  },

  computed: {
    isMobile() {
      return this.window.width < 640
    },
  },
}
</script>
