<template>
  <b-modal
    :active.sync="isActive"
    full-screen
    class="h-full clear-bg"
    scroll="keep"
    trap-focus
    aria-role="dialog"
    aria-modal
    :destroy-on-hide="true"
    :can-cancel="canCancel"
    @close="close()"
  >
    <div
      class="h-full flex flex-col bg-black bg-opacity-40 backdrop-blur-sm backdrop-filter justify-center overflow-y-auto"
    >
      <div
        class="bg-white shadow-lg mx-auto rounded-xl border border-gray-100 relative flex flex-col gap-5 z-50"
        :class="[
          { 'p-8': boxed === 'lg', 'p-5': !boxed || boxed === 'sm' },
          !['full', 'min'].includes(size) ? `max-w-${size}` : size == 'full' ? 'w-full' : '',
        ]"
      >
        <div v-if="title || $slots['toolbar']" class="flex gap-5">
          <div :class="`flex flex-1 justify-${titleAlign}`">
            <fw-heading>{{ title }}</fw-heading>
          </div>
          <div v-if="$slots['toolbar']" class="align-right">
            <slot name="toolbar"></slot>
          </div>
        </div>
        <div v-if="$slots['default']">
          <slot name="default"></slot>
        </div>
        <div v-if="$slots['footer']" class="flex justify-end">
          <slot name="footer"></slot>
        </div>
      </div>
      <div v-if="canCancel" class="absolute w-full h-full z-0" @click="close()"></div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'FwModal',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    titleAlign: {
      type: String,
      default: 'start',
    },
    size: {
      type: String,
      default: 'lg',
    },
    boxed: {
      type: String,
      default: 'sm',
    },
  },

  data() {
    return {
      isActive: this.active || false,
    }
  },

  watch: {
    active(value) {
      this.isActive = value
    },
  },

  methods: {
    close() {
      if (this.canCancel) {
        this.isActive = false
        this.$emit('close')
      }
    },
  },
}
</script>
<style>
.max-w-xl {
  max-width: 36rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-5xl {
  max-width: 64rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-7xl {
  max-width: 80rem;
}
</style>
