<template>
  <LayoutDefault
    :main-footer="false"
    :main-sidebar="false"
    :header-session-nav="false"
    :dark="false"
    :full="true"
    :force-extended-app-logo="true"
  >
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="centered-container">
        <fw-panel v-if="!emailSent" :title="$t('titleRecoverPassword')" custom-class="bg-white p-10 w-96">
          <fw-heading class="mb-5">{{ $t('titleUcAccount') }}</fw-heading>
          <form method="post" @submit.prevent="doRecover">
            <EmailInput email.sync="email" class="mb-2" @update:email="newValue => (email = newValue)" />

            <!-- Recover errors -->
            <div v-if="errorFound && errorFound != 'institutionalEmailFound'" class="my-5">
              <div class="font-bold text-sm mb-1 text-red-800">{{ $t('errorFound') }}</div>
              <div class="p-3 bg-red-50 rounded-lg mb-5 text-red-800 font-medium">
                <div class="max-w-sm mx-auto text-center">
                  <div v-if="errorFound == 'MaxAttempts'">
                    <div>{{ $t('errorTooManyAttempts') }}</div>
                  </div>
                  <div v-else-if="errorFound == 'EmailNotRegistered'">
                    <div>{{ $t('errorEmailNotRegistered') }}</div>
                  </div>
                  <div v-else-if="errorFound == 'EmailNotActivated'">
                    <div>{{ $t('errorEmailNotActivated') }}</div>
                  </div>
                  <div v-else>
                    <div class="has-text-tiny has-margin-top-small">{{ $t('errorID') }}: {{ errorFound }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Check for institutional email domains -->
            <div
              v-if="errorFound && errorFound == 'institutionalEmailFound'"
              class="p-5 rounded-xl text-sm text-gray-500 font-medium bg-gray-200 bg-opacity-50 text-center mt-2 mb-3"
            >
              <div class="font-bold text-md">
                {{ $t('institutionalAccountDetected') }}
              </div>
              <div class="mt-2">
                {{ $t('institutionalAccountFoundDescription') }}
              </div>
              <div class="mt-2 p-2">
                <a href="https://apps.uc.pt/recover?source=ucid" class="font-bold text-primary">{{
                  $t('recoverInstAccount')
                }}</a>
              </div>
            </div>

            <b-field>
              <b-button
                v-if="!errorFound"
                expanded
                :type="isAllowedEmail ? 'is-primary' : 'is-dark'"
                size="is-medium"
                @click="doRecover"
                >{{ $t('linkSendRecoverEmail') }}</b-button
              >
              <b-button v-else expanded :type="'is-light'" @click="$router.history.go()">{{
                $t('linkResetForm')
              }}</b-button>
            </b-field>

            <div v-if="!errorFound" class="text-gray-500 text-xs mt-2 mb-4">
              {{ $t('recoverInstDescription') }} <a href="https://apps.uc.pt/recover">apps.uc.pt/recover</a>.
            </div>

            <b-field class="text-center">
              <fw-button type="link-muted" size="sm" @click.native="$router.push({ name: 'home' })">{{
                $t('backToLogin')
              }}</fw-button>
            </b-field>
          </form>
        </fw-panel>
        <div v-else class="w-80">
          <div class="rounded-full mx-auto w-16 h-16 bg-primary flex items-center justify-center m-5">
            <svg
              class="fill-current h-8 w-8 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z"
              />
            </svg>
          </div>

          <fw-heading size="h3" class="text-center mb-4 text-primary">{{ $t('emailSent') }}</fw-heading>

          <div class="text-center text-gray-500 font-medium my-4">{{ $t('checkInbox') }}</div>
          <div v-if="emailService && emailService !== 'none'">
            <fw-button expanded type="light" @click.native="openEmailService"
              >{{ $t('actionsOpen') }} {{ emailService }}</fw-button
            >
          </div>
          <div class="text-center mt-5">
            <fw-button type="primary" expanded size="sm" @click.native="$router.push({ name: 'home' })">{{
              $t('goToLogin')
            }}</fw-button>
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'

import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import EmailInput from '@/fw-modules/fw-core-vue/id/components/inputs/EmailInput.vue'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/interface/components/header/HeaderLanguagePanel'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import utilsId from '@/fw-modules/fw-core-vue/id/utils'

export default {
  name: 'ExternalAccountRecoverPassword',

  components: {
    LayoutDefault,
    EmailInput,
    HeaderLanguagePanel,
  },

  data() {
    return {
      email: '',
      errorFound: '',
      emailSent: false,
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
  },

  computed: {
    allowExternalAccountsLogin() {
      return this.$store.state.allowExternalAccountsLogin
    },
    isAllowedEmail() {
      return this.email && !this.$v.$invalid && this.allowExternalAccountsLogin && !this.isInstitutionalEmail
    },
    isInstitutionalEmail() {
      if (this.email) return utilsId.isInstitutionalEmail(this.email)
      return false
    },
    isMobile() {
      return utils.isMobile()
    },
    allowPasswordless() {
      return this.debugMode && !this.isMobile
    },
    emailService() {
      if (this.email) return utilsId.emailService(this.email)
      return false
    },
  },

  created() {
    this.email = this.$route.query.email ? this.$route.query.email : ''
  },

  methods: {
    openEmailService() {
      let emailUrl = null
      if (this.emailService === 'webmail UC') {
        emailUrl = 'https://apps.uc.pt/mail'
      } else if (this.emailService === 'Gmail') {
        emailUrl = 'https://mail.google.com/'
      } else if (this.emailService === 'Outlook') {
        emailUrl = 'https://outlook.live.com/'
      } else {
        emailUrl = 'https://' + this.emailService
      }
      window.open(emailUrl, '_blank').focus()
    },

    async doRecover() {
      // Check if email is institutional
      if (this.isInstitutionalEmail) {
        this.errorFound = 'institutionalEmailFound'
        return
      }

      // Force form validation
      this.$v.$touch()

      if (!this.$v.$invalid && this.isAllowedEmail) {
        let result = false

        try {
          result = await ServiceAuth.recoverExternalAccount(this.email, process.env.VUE_APP_KEY)
        } catch (error) {
          this.errorFound = utils.errors(error).getKey() || 'Undefined'
          console.warn(`recover error: ${this.errorFound}`, error)
        }

        this.emailSent = result
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "titleRecoverPassword": "Conta UC",
    "titleUcAccount": "Recuperar password",
    "errorFound": "Ocorreu em erro",
    "errorTooManyAttempts": "Demasiadas tentativas. Por favor, tente mais tarde.",
    "errorEmailNotRegistered": "Endereço de email não registado.",
    "errorEmailNotActivated": "Conta não ativada.",
    "errorID": "ID do erro",
    "linkSendRecoverEmail": "Recuperar",
    "linkResetForm": "Cancelar",
    "goToLogin": "Aceder à página de autenticação",
    "emailSent": "Email de validação enviado",
    "checkInbox": "Verique a sua caixa de correio",
    "actionsOpen": "Abrir",
    "backToLogin": "voltar à página de autenticação",
    "institutionalAccountDetected": "Conta institucional detetada",
    "institutionalAccountFoundDescription": "O domínio do email que indica parece corresponder a uma conta institucional. Para recuperar a password, deverá aceder à plataforma \"Área Privada UC\", disponível através do link seguinte:",
    "recoverInstAccount": "Recuperar password de conta institucional",
    "recoverInstDescription": "O processo de recuperação de password disponível nesta página irá funcionar apenas para contas não institucionais da Universidade de Coimbra. Caso pretenda recuperar a passwod de uma conta institucional, por favor, aceda à página correspondente, disponível na \"Área Privada\", através do endereço"
  },
  "pt": {
    "titleRecoverPassword": "Conta UC",
    "titleUcAccount": "Recuperar password",
    "errorFound": "Ocorreu em erro",
    "errorTooManyAttempts": "Demasiadas tentativas. Por favor, tente mais tarde.",
    "errorEmailNotRegistered": "Endereço de email não registado.",
    "errorEmailNotActivated": "Conta não ativada.",
    "errorID": "ID do erro",
    "linkSendRecoverEmail": "Recuperar",
    "linkResetForm": "Cancelar",
    "goToLogin": "Aceder à página de autenticação",
    "emailSent": "Email de validação enviado",
    "checkInbox": "Verique a sua caixa de correio",
    "actionsOpen": "Abrir",
    "backToLogin": "voltar à página de autenticação",
    "institutionalAccountDetected": "Conta institucional detetada",
    "institutionalAccountFoundDescription": "O domínio do email que indica parece corresponder a uma conta institucional. Para recuperar a password, deverá aceder à plataforma \"Área Privada UC\", disponível através do link seguinte:",
    "recoverInstAccount": "Recuperar password de conta institucional",
    "recoverInstDescription": "O processo de recuperação de password disponível nesta página irá funcionar apenas para contas não institucionais da Universidade de Coimbra. Caso pretenda recuperar a passwod de uma conta institucional, por favor, aceda à página correspondente, disponível na \"Área Privada\", através do endereço"
  }
}
</i18n>
