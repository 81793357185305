/* eslint-disable no-unused-vars */

const INSTITUTIONAL_DOMAINS = [
  'adm.uc.pt',
  'alumni.fd.uc.pt',
  'auc.uc.pt',
  'bcs.uc.pt',
  'bg.uc.pt',
  'brainimaging.pt',
  'casadalusofonia.uc.pt',
  'cienciasemfronteiras.it',
  'cnc.uc.pt',
  'colegiodasartes.uc.pt',
  'csf.ccisp.pt',
  'darq.uc.pt',
  'dct.uc.pt',
  'dec.uc.pt',
  'dem.uc.pt',
  'efs.uc.pt',
  'fcdef.uc.pt',
  'fct.uc.pt',
  'fd.uc.pt',
  'fe.uc.pt',
  'ff.uc.pt',
  'fis.uc.pt',
  'fl.uc.pt',
  'fmed.uc.pt',
  'fpce.uc.pt',
  'gian.fis.uc.pt',
  'iav.uc.pt',
  'icnas.uc.pt',
  'ij.uc.pt',
  'itecons.uc.pt',
  'museudaciencia.com',
  'museudaciencia.org',
  'museudaciencia.pt',
  'museudaciencia.uc.pt',
  'pet.uc.pt',
  'qui.uc.pt',
  'sas.uc.pt',
  'saturno.fis.uc.pt',
  'sib.uc.pt',
  'student.darq.uc.pt',
  'student.dct.uc.pt',
  'student.dec.uc.pt',
  'student.dem.uc.pt',
  'student.fcdef.uc.pt',
  'student.fct.uc.pt',
  'student.fe.uc.pt',
  'student.ff.uc.pt',
  'student.fis.uc.pt',
  'student.fl.uc.pt',
  'student.fmed.uc.pt',
  'student.fpce.uc.pt',
  'student.qui.uc.pt',
  'student.uc.pt',
  'tagv.uc.pt',
  'uc.pt',
]

const envConfig = {
  production: {
    wsUrl: 'wss://fw.uc.pt',
    apiUrlIduc: 'https://id.fw.uc.pt',
    apiUrlMeetings: 'https://meetings.fw.uc.pt',
    apiUrlAcademic: 'https://academic.fw.uc.pt',
    apiUrlChat: 'https://chat.fw.uc.pt',
    apiUrlNotes: 'https://notes.fw.uc.pt',
    apiUrlStorage: 'https://storage.fw.uc.pt',
    apiUrlExams: 'https://exams.fw.uc.pt',
    apiUrlForms: 'https://forms.fw.uc.pt',
    apiUrlBuckets: 'https://buckets.fw.uc.pt',
    apiUrlNotifications: 'https://notifications.fw.uc.pt',
    apiUrlSpaces: '',
    apiUrlQflow: 'https://qflow.fw.uc.pt',
    ucpluginUrl: 'https://ucplugin.uc.pt',
    ucmeetingsUrl: 'https://ucmeetings.uc.pt',
    preview_url: 'https://preview.uc.pt',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'https://apply.fw.uc.pt/api',
    appUrlOne: 'https://one.uc.pt',
    apiUrlActivity: 'https://activity.fw.uc.pt',
    apiUrlTasks: '',
    apiUrlMyUC: '',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt'],
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
  },
  staging: {
    wsUrl: 'wss://fw.dev.ucframework.pt',
    apiUrlIduc: 'https://id.fw.dev.ucframework.pt',
    apiUrlMeetings: 'https://meetings.fw.dev.ucframework.pt',
    apiUrlAcademic: 'https://academic.fw.dev.ucframework.pt',
    apiUrlChat: 'https://chat.fw.dev.ucframework.pt',
    apiUrlNotes: 'https://notes.fw.dev.ucframework.pt',
    apiUrlStorage: 'https://storage.fw.dev.ucframework.pt',
    apiUrlExams: 'https://exams.fw.dev.ucframework.pt',
    apiUrlForms: 'https://forms.fw.dev.ucframework.pt',
    apiUrlBuckets: 'https://buckets.fw.dev.ucframework.pt',
    apiUrlNotifications: 'https://notifications.fw.dev.ucframework.pt',
    apiUrlSpaces: 'http://localhost:8013',
    apiUrlQflow: 'https://qflow.fw.dev.ucframework.pt',
    ucpluginUrl: 'https://plug.dev.ucframework.pt',
    ucmeetingsUrl: 'https://ucmeetings.dev.ucframework.pt',
    ucpreviewUrl: 'https://preview.dev.ucframework.pt',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'https://apply.fw.dev.ucframework.pt/api',
    apiUrlTasks: 'http://localhost:8015',
    appUrlOne: 'https://one.dev.ucframework.pt',
    apiUrlActivity: 'https://activity.fw.dev.ucframework.pt',
    apiUrlMyUC: 'http://localhost:8018',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['uc.pt', 'ucframework.pt'],
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
  },
  local: {
    wsUrl: 'ws://localhost:8020',
    apiUrlIduc: 'http://localhost:8002',
    apiUrlMeetings: 'http://localhost:8001',
    apiUrlAcademic: 'http://localhost:8004',
    apiUrlChat: 'http://localhost:8006',
    apiUrlNotes: 'http://localhost:8005',
    apiUrlStorage: 'http://localhost:8003',
    apiUrlExams: 'http://localhost:8000',
    apiUrlForms: 'http://localhost:8009',
    apiUrlBuckets: 'http://localhost:8012',
    apiUrlSpaces: 'http://localhost:8013',
    apiUrlNotifications: 'http://localhost:8007',
    apiUrlQflow: '',
    ucpluginUrl: 'https://plug.dev.ucframework.pt',
    ucmeetingsUrl: 'https://ucmeetings.dev.ucframework.pt',
    ucpreviewUrl: 'https://preview.dev.ucframework.pt',
    apiUrlPages: 'https://ucpages.uc.pt',
    apiUrlApply: 'http://localhost:8011/api',
    apiUrlTasks: 'http://localhost:8015',
    appUrlOne: 'https://one.dev.ucframework.pt',
    apiUrlActivity: 'http://localhost:8014',
    apiUrlMyUC: 'http://localhost:8018',
    //secureDomains are used to protect the user in case of wrong redirect in the notifications
    secureDomains: ['localhost', 'uc.pt', 'ucframework.pt'],
    institutionalDomains: INSTITUTIONAL_DOMAINS,
    semesterStart: ['09-01', '02-06'], //Start dates of semester: [1st Semester, 2nd Semester] format: MM-DD
    allowExternalAccountsRegister: process.env.VUE_APP_EXTERNAL_ACCOUNTS_REGISTER === 'true' ? true : false,
    allowExternalAccountsLogin: process.env.VUE_APP_EXTERNAL_ACCOUNTS_LOGIN === 'true' ? true : false,
  },
}

const FwEnvConfig =
  process.env.NODE_ENV == 'production'
    ? envConfig.production
    : process.env.NODE_ENV == 'local'
    ? envConfig.local
    : envConfig.staging

export default FwEnvConfig
