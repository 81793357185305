<template>
  <div
    :class="{
      'px-1 text-center rounded-full font-bold': type == 'rounded',
      'text-xs bg-opacity-50 font-normal leading-3 inline-flex': type == 'simple',
      'bg-teal-500 text-white': color == 'primary' && type != 'simple',
      'bg-red-600 bg-opacity-90 text-white': color == 'red' && type != 'simple',
      'bg-gray-500 bg-opacity-20 text-gray-600': color == 'light' && type != 'simple',
      'absolute -top-1 -right-2': !inline,
      'p-1': inline,
      'p-2': !inline && type == 'simple',
    }"
    :style="type == 'rounded' ? 'font-size: 0.8rem; min-width: 20px;' : ''"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'rounded',
    },
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'sm',
    },
    inline: Boolean,
  },
}
</script>
