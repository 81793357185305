<template>
  <LayoutDefault
    :main-footer="false"
    :main-sidebar="false"
    :header-session-nav="false"
    :dark="false"
    :full="true"
    :force-extended-app-logo="true"
  >
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="centered-container">
        <fw-panel
          v-if="!emailSent && !accountConfirmed"
          :title="$t('newAccount')"
          custom-class="bg-white p-10 w-full max-w-prose mb-5"
        >
          <div>
            <fw-heading class="mb-5">{{ token ? $t('validateNewAccount') : $t('createNewAccount') }}</fw-heading>

            <div v-if="token" class="mb-5">
              Para validar e finalizar o registo da sua nova conta externa UC, por favor, indique o seu nome completo e
              defina uma password segura.
            </div>

            <form autocomplete="off" method="post" @submit.prevent="doSignup">
              <b-field
                :label="$t('fullName')"
                label-for="full_name"
                :type="{ 'is-danger': $v.full_name.$error }"
                :message="$v.full_name.$error ? $t('fullnameRequired') : ''"
              >
                <b-input
                  v-model.trim="$v.full_name.$model"
                  :placeholder="$t('fullNamePlaceholder')"
                  name="full_name"
                  type="text"
                  custom-class="p-6"
                ></b-input>
              </b-field>

              <EmailInput v-if="!token" :email.sync="email" />
              <!-- Check for institutional email domains -->
              <div
                v-if="isInstitutionalEmail && !token"
                class="p-3 rounded-lg text-sm text-red-600 font-medium bg-red-100 bg-opacity-50 mb-3"
              >
                <div class="font-bold text-md">{{ $t('institutionalAccountDetected') }}</div>
                <div class="mt-2">{{ $t('institutionalAccountDetectedDescription') }}</div>
              </div>

              <b-field
                :label="$t('password')"
                label-for="password"
                :type="{ 'is-danger': $v.password.$error }"
                :message="$v.password.$error ? $t('passwordRequired') : ''"
              >
                <b-input
                  v-model.trim="$v.password.$model"
                  :placeholder="$t('passwordPlaceholder')"
                  type="password"
                  name="password"
                  custom-class="p-6"
                />
              </b-field>
              <password v-model="password" :strength-meter-only="true" :secure-length="8" />
              <div class="text-xs text-gray-500 mb-3">
                {{ $t('provideSafePassword') }}
              </div>
              <b-field :label="$t('privacyPoliceAcceptance')" class="mt-8">
                <div v-if="language === 'pt'" class="text-sm text-gray-500 content">
                  Declaro que li e tomei conhecimento da Política de Privacidade e de Proteção de Dados da Universidade
                  de Coimbra (UC), disponível na página web e mais declaro que tomei conhecimento, que:
                  <ol type="a" class="list-disc list-inside">
                    <li>
                      A finalidade da recolha dos meus dados pessoais pela UC, no presente contexto, destina-se à minha
                      identificação e credenciação como utilizador habituado a utilizar as funcionalidades da plataforma
                      <span class="uppercase">{{ appName }}</span> da UC;
                    </li>
                    <li>
                      Os dados pessoais solicitados, nome e email, são os dados considerados essenciais para a
                      organização administrativa e técnica da plataforma;
                    </li>
                    <li>
                      O prazo de conservação de dados pessoais é o que estiver fixado por norma legal ou regulamentar;
                    </li>
                    <li>
                      Na qualidade titular dos dados, em conformidade com a Lei, tenho os direitos de acesso e de
                      retificação, podendo estes ser exercidos, por escrito, mediante comunicação por correio eletrónico
                      para
                      <a href="sgrh@uc.pt">sgrh@uc.pt</a>;
                    </li>
                    <li>
                      O Encarregado de Proteção de Dados da UC pode ser contactado pelo email
                      <a href="mailto:epd@uc.pt">epd@uc.pt</a>.
                    </li>
                  </ol>
                </div>
                <div v-else class="text-sm text-gray-500 content">
                  Declaro que li e tomei conhecimento da Política de Privacidade e de Proteção de Dados da Universidade
                  de Coimbra (UC), disponível na página web e mais declaro que tomei conhecimento, que:
                  <ol type="a" class="list-disc list-inside">
                    <li>
                      A finalidade da recolha dos meus dados pessoais pela UC, no presente contexto, destina-se à minha
                      identificação e credenciação como utilizador habituado a utilizar as funcionalidades da plataforma
                      <span class="uppercase">{{ appName }}</span> da UC;
                    </li>
                    <li>
                      Os dados pessoais solicitados, nome e email, são os dados considerados essenciais para a
                      organização administrativa e técnica da plataforma;
                    </li>
                    <li>
                      O prazo de conservação de dados pessoais é o que estiver fixado por norma legal ou regulamentar;
                    </li>
                    <li>
                      Na qualidade titular dos dados, em conformidade com a Lei, tenho os direitos de acesso e de
                      retificação, podendo estes ser exercidos, por escrito, mediante comunicação por correio eletrónico
                      para
                      <a href="sgrh@uc.pt">sgrh@uc.pt</a>;
                    </li>
                    <li>
                      O Encarregado de Proteção de Dados da UC pode ser contactado pelo email
                      <a href="mailto:epd@uc.pt">epd@uc.pt</a>.
                    </li>
                  </ol>
                </div>
              </b-field>
              <b-field>
                <b-checkbox v-model="acceptTerms">
                  <div class="text-sm leading-4">{{ $t('acceptPolicy') }}</div>
                </b-checkbox>
              </b-field>

              <!-- Errors -->
              <div v-if="errorFound" class="my-5">
                <div class="font-bold text-sm mb-1 text-red-800">{{ $t('errorFound') }}</div>
                <div class="p-3 bg-red-50 rounded-lg mb-5 text-red-800 font-medium">
                  <div class="max-w-sm mx-auto text-center">
                    <div v-if="errorFound == 'MaxAttempts'">
                      <div>{{ $t('errorMaxAttempts') }}</div>
                    </div>
                    <div v-if="errorFound == 'Required'">
                      <div>{{ $t('errorRequired') }}</div>
                    </div>
                    <div v-else-if="errorFound == 'institutionalEmailFound'">
                      <div>{{ $t('errorInstitutionalEmailFound') }}</div>
                    </div>
                    <div v-else-if="errorFound == 'EmailAlreadyRegistered'">
                      <div>{{ $t('errorEmailAlreadyRegistered') }}</div>
                    </div>
                    <div v-else>
                      <div class="text-sm font-bold p-2">Error: {{ errorFound }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <b-field class="mt-5">
                <b-button
                  expanded
                  :disabled="!isSignupAllowed"
                  :type="isSignupAllowed && !errorFound ? 'is-primary' : 'is-dark'"
                  size="is-medium"
                  @click="doSignup"
                  >{{ token ? $t('linkConfirmAccount') : $t('linkRegisterAccount') }}</b-button
                >
              </b-field>
            </form>
            <div class="mt-5 text-center">
              <fw-button type="link-muted" size="sm" @click.native="$router.push({ name: 'home' })">{{
                $t('backToLogin')
              }}</fw-button>
            </div>
          </div>
        </fw-panel>
        <div v-else-if="accountConfirmed" class="w-80">
          <!-- Feedback icons -->
          <div
            class="rounded-full mx-auto w-16 h-16 flex items-center justify-center m-5"
            :class="{ 'bg-primary': !hasErrors, 'bg-red-600': !validating && hasErrors, 'bg-gray-500': validating }"
          >
            <fw-icon-close v-if="hasErrors" class="fill-current h-8 w-8 text-white" />
            <fw-icon-check v-else class="fill-current h-8 w-8 text-white" />
          </div>

          <fw-heading size="h3" class="text-center mb-4 text-primary">{{ $t('confirmedAccount') }}</fw-heading>

          <div>
            <div class="mb-5 text-center text-sm">{{ $t('canLoginNow') }}</div>
            <fw-button type="primary" expanded size="sm" @click.native="$router.push({ name: 'home' })">{{
              $t('goToLogin')
            }}</fw-button>
          </div>
        </div>
        <div v-else class="w-80">
          <div class="rounded-full mx-auto w-16 h-16 bg-primary flex items-center justify-center m-5">
            <fw-icon-message-sent class="fill-current h-8 w-8 text-white"></fw-icon-message-sent>
          </div>

          <fw-heading size="h3" class="text-center mb-4 text-primary">{{ $t('emailSent') }}</fw-heading>

          <div class="text-center text-gray-500 font-medium my-4">{{ $t('checkInbox') }}</div>
          <div v-if="emailService && emailService !== 'none'">
            <fw-button expanded type="light" @click.native="openEmailService"
              >{{ $t('open') }} {{ emailService }}</fw-button
            >
          </div>

          <!-- Try again -->
          <div class="text-center my-10 flex flex-col items-center justify-center text-sm">
            <div class="text-gray-400 font-semibold">{{ $t('noEmailArrived') }}</div>
            <div v-if="countDown && countDown < 10" class="text-xs text-gray-500 p-3">
              {{ $t('canResendValidationEmail') }}
              <span class="w-4 inline-block text-center">{{ countDown }}</span> {{ $t('seconds') }}
            </div>
            <fw-button v-else type="link-light" size="sm" @click.native="resendEmail">{{
              $t('sendValidationEmailAgain')
            }}</fw-button>
          </div>

          <div class="text-center">
            <fw-button type="link-muted" size="sm" @click.native="$router.push({ name: 'home' })">{{
              $t('backToLogin')
            }}</fw-button>
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
// Required
// MinStringLength
// MaxStringLength
// OneNumberRequired
// ThreeLettersRequired
// PasswordEqualToEmail
// PasswordWithEmailWords

// min 8 chars
// min 1 numero
// min 3 letras (minusculas ou maiusculas)

import { required, minLength } from 'vuelidate/lib/validators'
import Password from 'vue-password-strength-meter'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/interface/components/header/HeaderLanguagePanel'
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import utilsId from '@/fw-modules/fw-core-vue/id/utils'
import EmailInput from '@/fw-modules/fw-core-vue/id/components/inputs/EmailInput.vue'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'

export default {
  name: 'ExternalAccountSignup',

  components: {
    LayoutDefault,
    Password,
    EmailInput,
    HeaderLanguagePanel,
  },

  data() {
    return {
      action: '',
      accountConfirmed: false,
      emailSent: false,
      emailResent: false,
      full_name: this.$route.query.fullname ? this.$route.query.fullname : '',
      email: this.$route.query.email ? this.$route.query.email : '',
      password: '',
      acceptTerms: false,
      errorFound: '',
      token: this.$route.query.t ? this.$route.query.t : null,
      isEmailNotAllowed: false,
      countDown: 10,
    }
  },

  validations: {
    email: {
      required,
      min: minLength(5),
      notInstitutionalEmail: val => !utilsId.isInstitutionalEmail(val),
    },
    password: { required, min: minLength(8) },
    full_name: { required, min: minLength(3) },
    acceptTerms: { accepted: val => val === true },
  },

  computed: {
    language() {
      return this.$store.state.language
    },
    emailService() {
      if (this.email) return utilsId.emailService(this.email)
      return false
    },
    allowExternalAccountsRegister() {
      return this.$store.state.allowExternalAccountsRegister
    },
    isSignupAllowed() {
      return (
        (this.token || this.email) &&
        (this.allowExternalAccountsRegister || (!this.allowExternalAccountsRegister && this.token)) &&
        this.isFormReady
      )
    },
    isFormReady() {
      return this.token
        ? !this.$v.full_name.$invalid && !this.$v.password.$invalid && !this.$v.acceptTerms.$invalid
        : !this.$v.$invalid
    },
    isInstitutionalEmail() {
      if (this.email) return utilsId.isInstitutionalEmail(this.email)
      return false
    },
    isMobile() {
      return utils.isMobile()
    },
    appName() {
      return this.$store.state.appName || 'UC APP'
    },
  },

  created() {
    this.email = this.$route.query.email ? this.$route.query.email : ''
    this.full_name = this.$route.query.fullname ? this.$route.query.fullname : ''
    this.action = this.$route.query.action ? this.$route.query.action : ''
    // if (this.action == 'confirm' && this.email) {
    //   this.resendEmail()
    // }
  },

  methods: {
    async doSignup() {
      // Check if email is institutional
      if (this.isInstitutionalEmail) {
        this.errorFound = 'institutionalEmailFound'
        return
      }

      this.$v.$touch()

      // If we already have a token, the account shoud be validated
      if (this.token) {
        if (this.isFormReady) {
          this.errorFound = false
          let result = false
          try {
            result = await ServiceAuth.signupExternalAccountWithToken(
              this.token,
              this.password,
              this.full_name,
              process.env.VUE_APP_KEY,
            )
            this.accountConfirmed = result
          } catch (error) {
            this.errorFound = utils.errors(error).getKey() || 'Undefined'
            console.warn(`login error: ${this.errorFound}`, error)
          }
        }
      }

      // Standard method
      else {
        if (!this.$v.$invalid && this.isSignupAllowed) {
          this.errorFound = false
          let result = false

          try {
            result = await ServiceAuth.signupExternalAccount(
              this.email,
              this.password,
              this.full_name,
              process.env.VUE_APP_KEY,
            )
          } catch (error) {
            this.errorFound = utils.errors(error).getKey() || 'Undefined'
            console.warn(`login error: ${this.errorFound}`, error)
          }
          this.emailSent = result
        }
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    async resendEmail() {
      this.errorFound = false
      let result = false

      try {
        result = await ServiceAuth.resendConfirmationEmail(this.email, process.env.VUE_APP_KEY)
      } catch (error) {
        this.errorFound = utils.errors(error).getKey() || 'Undefined'
        console.warn(`login error: ${this.errorFound}`, error)

        this.$buefy.snackbar.open({
          message: this.$t('errorTooManyAttempts'),
          type: 'is-danger',
          position: 'is-top-right',
          duration: 2000,
          queue: false,
        })
        return
      }

      this.emailResent = result

      if (result) {
        this.countDown = 10
        this.countDownTimer()
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "provideSafePassword": "Defina uma password segura, com o mínimo de 8 caracteres, 3 dos quais devem ser letras e um dígito. A password será validada novamente após submeter o formulário de registo.",
    "newAccount": "Conta Externa UC",
    "validateNewAccount": "Validar nova conta",
    "createNewAccount": "Registar nova conta",
    "fullName": "Nome completo",
    "fullnameRequired": "Nome completo obrigatório",
    "fullNamePlaceholder": "Indique o seu nome",
    "institutionalAccountDetected": "Conta institucional detetada",
    "institutionalAccountDetectedDescription": "O endereço de email que indicou pertence a um domínio institucional da UC. Neste sentido, não é necessário realizar o registo de uma nova conta, basta autenticar-se na aplicação com o email indicado e respetiva password.",
    "password": "Password",
    "passwordRequired": "Password obrigatória",
    "passwordPlaceholder": "Indique uma password segura (8 caracteres, 3 letras, 1 dígito)",
    "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
    "acceptPolicy": "Aceito os termos de utilização e política de privacidade.",
    "errorFound": "Ocorreu um erro",
    "errorMaxAttempts": "Demasiadas tentativas. Por favor, tente novamente mais tarde.",
    "errorTooManyAttempts": "Demasiadas tentativas. Por favor, tente novamente mais tarde.",
    "errorRequired": "Obrigatório.",
    "errorInstitutionalEmailFound": "Email institucional encontrado",
    "errorEmailAlreadyRegistered": "O email já se encontra registado.",
    "linkConfirmAccount": "Confirmar conta",
    "linkRegisterAccount": "Registar conta",
    "backToLogin": "voltar à página de autenticação",
    "confirmedAccount": "Conta confirmada",
    "canLoginNow": "A sua conta foi registada e validada com sucesso e já poderá autenticar-se na plataforma.",
    "goToLogin": "Entrar na página de autenticação",
    "emailSent": "Email enviado",
    "checkInbox": "Verifique a sua caixa de correio",
    "open": "Abrir",
    "noEmailArrived": "O código de validação não chegou?",
    "canResendValidationEmail": "Pode solictar um novo código de validação dentro de ",
    "seconds": "segundos",
    "sendValidationEmailAgain": "Reenviar código de validação"
  },
  "en": {
    "provideSafePassword": "Defina uma password segura, com o mínimo de 8 caracteres, 3 dos quais devem ser letras e um dígito. A password será validada novamente após submeter o formulário de registo.",
    "newAccount": "Conta Externa UC",
    "validateNewAccount": "Validar nova conta",
    "createNewAccount": "Registar nova conta",
    "fullName": "Nome completo",
    "fullnameRequired": "Nome completo obrigatório",
    "fullNamePlaceholder": "Indique o seu nome",
    "institutionalAccountDetected": "Conta institucional detetada",
    "institutionalAccountDetectedDescription": "O endereço de email que indicou pertence a um domínio institucional da UC. Neste sentido, não é necessário realizar o registo de uma nova conta, basta autenticar-se na aplicação com o email indicado e respetiva password.",
    "password": "Password",
    "passwordPlaceholder": "Indique uma password segura (8 caracteres, 3 letras, 1 dígito)",
    "passwordRequired": "Password obrigatória",
    "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
    "acceptPolicy": "Aceito os termos de utilização e política de privacidade.",
    "errorFound": "Ocorreu um erro",
    "errorMaxAttempts": "Demasiadas tentativas. Por favor, tente novamente mais tarde.",
    "errorTooManyAttempts": "Demasiadas tentativas. Por favor, tente novamente mais tarde.",
    "errorRequired": "Obrigatório.",
    "errorInstitutionalEmailFound": "Email institucional encontrado",
    "errorEmailAlreadyRegistered": "O email já se encontra registado.",
    "linkConfirmAccount": "Confirmar conta",
    "linkRegisterAccount": "Registar conta",
    "backToLogin": "voltar à página de autenticação",
    "confirmedAccount": "Conta confirmada",
    "canLoginNow": "A sua conta foi registada e validada com sucesso e já poderá autenticar-se na plataforma.",
    "goToLogin": "Entrar na página de autenticação",
    "emailSent": "Email enviado",
    "checkInbox": "Verifique a sua caixa de correio",
    "open": "Abrir",
    "noEmailArrived": "O código de validação não chegou?",
    "canResendValidationEmail": "Pode solictar um novo código de validação dentro de ",
    "seconds": "segundos",
    "sendValidationEmailAgain": "Reenviar código de validação"
  }
}
</i18n>
