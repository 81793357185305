<template>
  <div class="fw-panel-page-header">
    <div class="flex gap-5 my-2 relative" :class="{ 'px-5': addLateralPadding }">
      <div class="flex-1">
        <fw-heading size="h2">{{ title }}</fw-heading>
        <fw-label v-if="lead" class="opacity-50 mt-0 mb-3">{{ lead }}</fw-label>
      </div>
      <div class="fw-panel-page-header-toolbar">
        <slot name="toolbar" />
      </div>
    </div>
    <div class="fw-panel-page-header-nav">
      <slot name="nav" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    lead: {
      type: String,
      default: null,
    },
    addLateralPadding: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
