<template>
  <svg class="fill-current" width="14" height="20" viewBox="0 0 14 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6 1.40109e-08H1.4C1.0287 1.40109e-08 0.672601 0.144607 0.410051 0.40201C0.1475 0.659413 1.42911e-08 1.00853 1.42911e-08 1.37255V4.90196C1.42911e-08 5.11504 0.0506032 5.3252 0.147802 5.51578C0.245001 5.70637 0.386126 5.87215 0.56 6L6 10L0.56 14C0.386126 14.1278 0.245001 14.2936 0.147802 14.4842C0.0506032 14.6748 1.42911e-08 14.885 1.42911e-08 15.098V18.6275C-2.62568e-05 18.8077 0.0361679 18.9862 0.106515 19.1527C0.176862 19.3193 0.279985 19.4706 0.409992 19.598C0.539999 19.7255 0.694345 19.8266 0.864212 19.8956C1.03408 19.9645 1.21614 20 1.4 20H12.6C12.9713 20 13.3274 19.8554 13.5899 19.5979C13.7199 19.4705 13.8231 19.3192 13.8934 19.1527C13.9638 18.9862 14 18.8077 14 18.6275V15.1373C14 14.9242 13.9494 14.714 13.8522 14.5234C13.755 14.3329 13.6139 14.1671 13.44 14.0392L8 10L13.44 5.96078C13.6139 5.83295 13.755 5.66717 13.8522 5.47658C13.9494 5.28599 14 5.07583 14 4.86274V1.37255C14 1.1923 13.9638 1.0138 13.8935 0.847267C13.8231 0.68073 13.72 0.529411 13.59 0.401953C13.46 0.274495 13.3057 0.173395 13.1358 0.104427C12.9659 0.0354587 12.7839 -2.57419e-05 12.6 1.40109e-08ZM1.4 1.17647H12.6C12.653 1.17647 12.7039 1.19713 12.7414 1.2339C12.7789 1.27067 12.8 1.32055 12.8 1.37255V3.13725H1.2V1.37255C1.2 1.32055 1.22107 1.27067 1.25858 1.2339C1.29609 1.19713 1.34696 1.17647 1.4 1.17647ZM12.8 15.1373V18.6275C12.8 18.6795 12.7789 18.7293 12.7414 18.7661C12.7039 18.8029 12.653 18.8235 12.6 18.8235H1.4C1.34696 18.8235 1.29609 18.8029 1.25858 18.7661C1.22107 18.7293 1.2 18.6795 1.2 18.6275V15.098C1.2007 15.0677 1.20824 15.0379 1.22207 15.0108C1.23591 14.9837 1.25569 14.9599 1.28 14.9412L7 10.7353L12.72 14.9804C12.7461 14.9974 12.7673 15.0209 12.7813 15.0484C12.7953 15.0759 12.8018 15.1065 12.8 15.1373ZM12.72 5.01961L7 9.26471L1.28 5.05882C1.25571 5.04006 1.23594 5.01628 1.22211 4.98916C1.20828 4.96204 1.20073 4.93227 1.2 4.90196V4.31373H12.8V4.86274C12.8018 4.89346 12.7953 4.92409 12.7813 4.95161C12.7673 4.97913 12.7461 5.00257 12.72 5.01961Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconCourseTime',
}
</script>
